#tracking {
    .map {
        margin: 0 !important;
    }
    .driver-section-top {
        display: flex;
        flex-wrap: wrap;
        min-height: 100px;
        align-items: center;
        background-color: gray;
        justify-content: space-between;
        .driver-info {
            flex-grow: 1;
            padding: 10px 10px 0 10px;
            .driver-name {
                color: #fff;
                font-weight: bold;
            }
            .driver-location {
                display: flex;
                max-height: 20px;
                overflow: visible;
                align-items: center;
                justify-content: space-between;
                color: rgba($color: #fff, $alpha: 0.8);
            }
            .driver-arriving {
                color: rgba($color: #fff, $alpha: 0.8);
                b {
                    color: #fff;
                    font-size: larger;
                }
            }
            .driver-arriving-2 {
                padding-left: 20px;
                color: rgba($color: #fff, $alpha: 0.8);
                b {
                    color: #fff;
                    font-size: larger;
                }
            }
        }
        .driver-photo {
            height: 100px;
            min-width: 75px;
            img {
                width: auto;
                height: 100%;
            }
        }
        .driver-contact {
            padding: 0;
            width: 60px;
            display: flex;
            color: #fff;
            font-size: 20px;
            align-items: center;
            justify-content: space-between;
            .driver-sms,
            .driver-phone {
                color: #fff;
            }
        }
        .driver-travel-time {
            padding: 0;
            color: #fff;
            width: fit-content;
        }
    }
    .driver-section-bottom {
        padding: 20px 0;
        .driver-info {
            .driver-notify {
                color: red;
            }
            .driver-car {
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                justify-content: space-between;
                li {
                    min-width: 280px;
                    list-style: none;
                }
                .driver-label {
                    font-weight: 500;
                }
            }
        }
        .booking-info {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                min-width: 280px;
                list-style: none;
                a {
                    color: #212529;
                }
            }
            .booking-label {
                font-weight: 500;
            }
        }
    }
}

.marker-label {
    top: -5px;
    right: -55px;
    padding: 5px 10px;
    border-radius: 4px;
    position: absolute;
    border: 1px solid #ccc;
    background-color: #fff;
}

@media (max-width: 767px) {
    #tracking {
        .driver-section-top {
            position: relative;
            padding-bottom: 40px;
            .driver-info {
                max-width: 65%;
                text-align: right;
                .driver-location {
                    align-items: end;
                    max-height: unset;
                    flex-direction: column;
                }
                .driver-arriving {
                    left: 10px;
                    bottom: 8px;
                    position: absolute;
                }
            }
            .driver-photo {
                max-width: 35%;
            }
            .driver-contact {
                padding-top: 5px;
            }
            .driver-travel-time {
                padding-top: 5px;
            }
        }
    }
}

@media (max-width: 480px) {
    #tracking {
        .driver-section-top {
            .driver-info {
                .driver-arriving {
                    font-size: 15px;
                }
            }
            .driver-photo {
                max-width: 135px;
                overflow: hidden;
            }
        }
    }
}