.top-header {
    height: 80px;
    display: flex;
    color: #fff;
    font-size: 16px;
    align-items: center;
    background-color: #000;
    justify-content: space-between;
    border-bottom: 2px solid #fff;
    .container {
        .d-flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .information {
                gap: 20px;
                display: flex;
                align-items: center;
            }
        }
    }
}

@media (max-width: 1023px) {
    .top-header {
        height: 40px;
    }
}

@media (max-width: 767px) {
    .top-header {
        display: none;
    }
}