.timeline {
  padding: 0;
  list-style: none;
  position: relative;
  margin: 10px 0 -10px 0;

  &:before {
    top: 0;
    bottom: 0;
    left: 22px;
    width: 1px;
    content: " ";
    position: absolute;
    background-color: #ccc;
  }

  li {
    padding: 10px 0;
    position: relative;

    &:before {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
      content: " ";
      display: table;
    }

    .timeline-badge {
      top: 26px;
      z-index: 0;
      left: 15px;
      color: #fff;
      width: 16px;
      height: 16px;
      font-size: 1.4em;
      line-height: 50px;
      text-align: center;
      position: absolute;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      background-color: gray !important;
    }
  }
}
