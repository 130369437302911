.ui-control {
    .nav.nav-pills {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 0;
        li {
            width: 50%;
            margin-left: 0;
            text-align: center;
            background-color: #fff;
            border: none;
            border-radius: 0;
            a {
                border: none;
                margin-bottom: 0;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 600;
            }
            &.active a {
                background-color: #3699dd;
            }
        }
    }
    &.add-info {
        color: #211f1f;
        font-size: 16px;
        .add-location {
            border: none;
            font-weight: bold;
            color: gray;
            background-color: transparent;
            &:hover {
                color: #13527c;
                text-decoration: none;
                background-color: transparent;
            }
        }
    }
    border: none;
    margin-top: 20px;
    position: relative;
    &.center {
        float: none;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    &.ui-control-small {
        margin-top: 10px;
        .ui-checkbox {
            margin-bottom: 0;
        }
    }
    &.ui-control-haslabel .ui-dropdown {
        height: 60px;
        .ui-dropdown-label {
            line-height: 58px;
        }
    }
    &.desktop {
        display: block;
    }
    &.mobile {
        display: none;
    }
    textarea {
        height: auto;
        resize: none;
        font-size: 14px;
        padding-top: 10px;
        &.note {
            margin-top: 12px;
        }
        &.textarea-input {
            font-size: 16px;
        }
    }
    .datetime {
        padding: 0;
        text-align: left;
        .item {
            width: 20%;
            float: left;
            margin-top: 0;
            padding-top: 0;
            cursor: pointer;
            text-align: center;
            font-weight: normal;
            color: #211f1f;
            text-decoration: none;
            border: 1px solid #ccc;
            background-color: #fff;
            text-transform: uppercase;
            border-radius: 0;
            input {
                height: 50px;
                font-size: 16px;
                border-radius: 0;
            }
        }
    }
    input.haslabel {
        font-weight: bold;
        color: #211f1f;
        height: 60px !important;
        font-size: 14px !important;
        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
            top: 10px;
            opacity: 1;
            font-size: 13px;
            text-transform: uppercase;
        }
    }
    textarea.haslabel {
        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
            top: 10px;
            opacity: 1;
            font-size: 13px;
            text-transform: uppercase;
        }
    }
    input.haslabel {
        &:valid,
        &:focus {
            padding-top: 30px;
        }
    }
    textarea.haslabel {
        &:valid,
        &:focus {
            padding-top: 30px;
        }
    }
    input.haslabel ~ label,
    textarea.haslabel ~ label {
        left: 51px;
        opacity: 0.8;
        color: #a8a9aa;
        font-size: 16px;
        font-weight: bold;
        position: absolute;
        font-weight: normal;
        pointer-events: none;
        top: 10px !important;
        transition: 0.2s ease all;
    }
    input.haslabel ~ label.nopadding,
    textarea.haslabel ~ label.nopadding {
        left: 12px;
    }
    input.haslabel ~ label.label-phone-number,
    textarea.haslabel ~ label.label-phone-number {
        left: 85px;
    }
    input.haslabel ~ label.label-country-number,
    textarea.haslabel ~ label.label-country-number {
        left: 12px;
    }
    .ui-invalid {
        color: red;
        display: none;
        font-size: 16px;
        padding-top: 5px;
        text-align: left;
        text-transform: none;
        background-color: transparent;
    }
}

.control .ui-invalid {
    color: red;
    display: none;
    font-size: 16px;
    padding-top: 5px;
    text-align: left;
    text-transform: none;
    background-color: transparent;
}

.ui-control .error {
    input,
    textarea {
        border: 1px solid red;
    }
}

.control .error {
    input,
    textarea {
        border: 1px solid red;
    }
}

.ui-control .error .ui-invalid,
.control .error .ui-invalid {
    display: block;
}

.ui-control .valid {
    input,
    textarea {
        border: 1px solid #ccc;
    }
}

.control .valid {
    input,
    textarea {
        border: 1px solid #ccc;
    }
}

.ui-control .valid .ui-invalid,
.control .valid .ui-invalid {
    display: none;
}

.input-invalid {
    border: 1px solid #b70016 !important;
}

.intl-tel-input {
    width: 100%;
}

input:-webkit-autofill {
    background-color: transparent;
    color: inherit;
}
