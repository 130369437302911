.ui-calendar {
  width: 100%;
}

.ui-calendar input {
  width: 100%;
}

.ui-calendar button {
  top: 0px;
  margin: 0;
  right: 0px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  color: #063e63 !important;
  background: #fff !important;
}

.ui-calendar button.close {
  display: none;
}

.ui-calendar .ui-input {
  padding-left: 50px;
}

.ui-datepicker {
  width: 100%;
  cursor: default;
  min-height: 100px;
  padding: 0 0 0 10px;
}

.ui-datepicker #datetime-table {
  background-color: #fff;
}

.ui-datepicker .ui-footer {
  width: 100%;
  padding: 10px;
  display: block;
  margin-top: 0px;
  padding-top: 5px;
  text-align: center;
  border-top: 1px solid #efefef;
}

.ui-datepicker .ui-footer a {
  color: #fff;
  width: 156px;
  height: 35px;
  opacity: 0.9;
  display: block;
  margin: 10px;
  cursor: pointer;
  line-height: 35px;
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
  background-color: gray;
}

.ui-datepicker .ui-footer a:hover {
  opacity: 1;
}

.ui-datepicker .ui-footer a.btnCancel {
  color: #444;
  border: none;
  opacity: 0.9;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.ui-datepicker .ui-footer a.btnCancel:hover {
  opacity: 1;
  border: 1px solid #ccc;
}

.ui-datepicker .ui-footer span {
  color: red;
  display: block;
}

.ui-datepicker .ui-header {
  width: 100%;
  padding: 10px;
  display: flex;
  margin-top: 0px;
  padding-top: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #ccc;
}

.ui-datepicker .ui-header:after {
  content: none !important;
}

.ui-datepicker .ui-header span {
  color: red;
  display: block;
  margin-bottom: 5px;
}

.ui-datepicker .ui-header a {
  display: flex;
  width: 150px;
  height: 35px;
  margin: 10px;
  cursor: pointer;
  color: #999999;
  border-radius: 5px;
  align-items: center;
  text-decoration: none;
  border: 1px solid #ccc;
  background-color: #fff;
  justify-content: center;
}

.ui-datepicker .ui-header a:hover,
.ui-datepicker .ui-header a.active {
  color: #fff;
  background-color: #3699dd;
}

.ui-datepicker .ui-minutepicker-header {
  width: 100%;
  border: none;
  padding: 20px 0;
  background: #fff;
  text-align: center;
  border-bottom: 1px solid #eee;
}

.ui-datepicker .ui-minutepicker-header a {
  width: 100px;
  display: inline-block;
  text-decoration: none;
  height: auto;
  margin-top: 0;
  padding-top: 0;
  line-height: 28px;
  background-color: transparent;
  color: #0f76bb;
  font-weight: normal;
  font-size: 13px;
  border: 1px solid #0f76bb;
  text-transform: uppercase;
  border-radius: 5px;
}

.ui-datepicker .ui-minutepicker-header a:hover {
  color: #ffffff;
  font-weight: bold;
  background-color: #0f76bb;
}

.ui-datepicker .ui-minutepicker-header a.disabled {
  color: #a4a4a4;
  pointer-events: none;
  border: 1px solid #bcbcbc;
  cursor: default !important;
}

.ui-datepicker .ui-date-content {
  margin: 0;
  margin-top: 10px;
  padding: 0 15px 0 0;
  border-right: 1px solid #efefef;
}

.ui-datepicker .ui-datepicker-header {
  padding: 0;
  color: #444;
  border: none;
  background: #fff;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  color: gray;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.ui-datepicker .ui-datepicker-calendar {
  width: 100%;
}

.ui-datepicker .ui-datepicker-calendar thead th {
  padding: 2px;
  color: gray;
  text-align: center;
  background-color: #fff;
  text-transform: uppercase;
}

.ui-datepicker .ui-datepicker-calendar td a {
  padding: 4px 6px;
  color: #211f1f;
  font-weight: bold;
  text-align: center;
  text-decoration: none !important;

  &.ui-state-active {
    border: 1px solid #ccc;
    background-color: gainsboro;
  }
}

.ui-datepicker .ui-time-content {
  padding: 0;
}

.ui-datepicker .ui-time-content .ui-timepicker {
  border: none;
  color: gray;
  margin-top: 10px;
  background: #fff;
  text-align: center;
}

.ui-datepicker .ui-time-content .ui-timepicker p {
  font-weight: normal;
  font-size: 14px;
  color: #444;
}

.ui-datepicker .ui-time-content .ui-timepicker div {
  margin: 0;
  color: gray;
  display: block;
  min-width: 24px;
}

.ui-datepicker .ui-time-content .ui-timepicker div a {
  height: 28px;
  width: 5.2em;
  margin: 0 auto;
  display: block;
  border-radius: 5px;
  background-color: #c9c9c9;
}

.ui-datepicker .ui-time-content .ui-timepicker div span {
  color: #fff;
  font-size: 14px;
  line-height: 26px;
}

.ui-datepicker .ui-time-content .ui-timepicker div .span-number {
  padding: 0;
  width: 74px;
  height: 40px;
  color: #073a5d;
  text-indent: 0;
  text-align: center;
  border-radius: 4px;
  font-weight: normal;
  border: 1px solid #ccc;
  background-color: #fff;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.ui-datepicker .ui-time-content .ui-timepicker .ui-hour-up,
.ui-datepicker .ui-time-content .ui-timepicker .ui-minute-up {
  cursor: pointer;
  text-align: center;
  margin-bottom: 20px;
}

.ui-datepicker .ui-time-content .ui-timepicker .ui-hour-down,
.ui-datepicker .ui-time-content .ui-timepicker .ui-minute-down {
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  top: 0;
  color: #fff;
  width: 26px;
  height: 26px;
  opacity: 0.8;
  cursor: pointer;
  border-radius: 0;
  position: absolute;
  border-radius: 5px;
  text-decoration: none;
  background-color: #3699dd;
}

.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  opacity: 1;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  top: 50%;
  left: 54%;
  font-size: 12px;
  margin-top: -6px;
}

.ui-datepicker .ui-datepicker-prev span {
  margin-left: -6px;
}

.ui-datepicker .ui-datepicker-next span {
  margin-left: -8px;
}

.ui-datepicker .ui-datepicker-trigger .glyphicon {
  opacity: 0.6;
  color: #929497;
  padding: 16px 18px;
}

.ui-datepicker .ui-datepicker-trigger .glyphicon:hover {
  opacity: 1;
}

.ui-datetime-invalid {
  color: #fff;
  display: block;
  font-size: 16px;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #f97203;
}

.ui-datetime-status {
  line-height: 30px;
  border: 1px solid #ccc;
  border-top: none;
  padding-left: 10px;
}

.ui_tpicker_hour,
.ui_tpicker_minute {
  text-align: center;
}

.ui-datepicker-today a {
  border: 1px solid #ccc !important;
}

@media (max-width: 991px) and (min-width: 768px) {
  .ui-datepicker .ui-date-content {
    width: 55%;
  }

  .ui-datepicker .ui-time-content {
    width: 45%;
  }
}

@media (max-width: 767px) and (min-width: 568px) {
  .ui-datepicker .ui-date-content {
    width: 55%;
  }

  .ui-datepicker .ui-time-content {
    width: 45%;
  }
}

@media (max-width: 567px) {
  .ui-calendar button.close {
    margin: 0;
    z-index: 10;
    opacity: 0.5;
    width: 30px;
    height: 30px;
    display: block;
    cursor: pointer;
    position: absolute;
    font-weight: normal;
    border-radius: 50px;
    font-family: rocobo;
    border: 2px solid #fff;
    color: #fff !important;
    outline: none !important;
    background-color: #000 !important;
  }

  .ui-calendar button.close:hover {
    opacity: 0.7;
  }

  .ui-datepicker {
    bottom: 0;
    padding: 0;
    border: none;
    height: 100%;
    overflow-y: auto;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    z-index: 10000 !important;
    position: fixed !important;
  }

  .ui-datepicker .ui-time-content {
    margin-bottom: 10px;
  }

  .ui-datepicker .ui-date-content {
    padding: 0 10px;
    margin-top: 10px auto 0 auto;
  }

  .ui-datepicker .ui-minutepicker-header {
    margin: 0;
    padding: 10px 0;
  }

  .ui-datepicker .ui-minutepicker-header a {
    width: 80px;
  }

  .ui-datepicker .ui-time-content .ui-timepicker {
    padding: 0;
    margin: 10px;
  }

  .ui-datepicker .ui-time-content .ui-timepicker .ui-hour-up,
  .ui-datepicker .ui-time-content .ui-timepicker .ui-minute-up {
    margin-bottom: 10px;
  }

  .ui-datepicker .ui-time-content .ui-timepicker .ui-hour-down,
  .ui-datepicker .ui-time-content .ui-timepicker .ui-minute-down {
    margin-top: 10px;
  }

  .ui-datepicker .ui-footer {
    margin: 0;
    padding: 0;
  }

  .ui-datepicker .ui-header {
    margin: 0 !important;
    padding: 0 !important;
  }

  .ui-datepicker .ui-footer a {
    width: 100px;
    margin-left: 5px;
    display: inline-block;
  }

  .ui-datepicker .ui-header a {
    width: 100px !important;
  }
}

input[type="datetime-local"] {
  width: 100%;
  padding-left: 50px;
  background-color: transparent;
}

input[type="datetime-local"]:hover:after {
  opacity: 1;
  border-left: 1px solid #ccc;
}

input[type="datetime-local"]:after {
  top: 0;
  right: 0;
  margin: 0px;
  width: 48px;
  opacity: 0.8;
  height: 100%;
  display: block;
  content: "\e259";
  line-height: 50px;
  text-align: center;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-family: "Glyphicons Halflings";
}

input[type="datetime-local"]:before {
  color: lightgray;
  content: attr(placeholder);
}

input[type="datetime-local"].full:before {
  content: "" !important;
}

@-moz-document url-prefix() {
  select.span-number {
    text-indent: 0 !important;
  }
}
