#pickcar {
  margin-top: 30px;
  .media {
    padding: 15px;
    border-top: 0;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    &.active {
      border: 2px solid yellowgreen;
    }
    .media-left {
      width: 40%;
    }
    .media-object {
      width: 90%;
    }
    .media-heading {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .vehicle-price {
      margin: 0;
      font-weight: bold;
      color: yellowgreen;
    }
    .media-text {
      text-align: justify;
    }
    .media-icon {
      div {
        margin-right: 10px;
      }
      img {
        margin-top: -5px;
      }
    }
  }
}

@media (max-width: 767px) {
  #pickcar .media {
    .media-left {
      margin: 0;
      padding: 0;
      width: 100%;
      display: block;
    }
    .media-object {
      width: 100%;
    }
    .media-text {
      margin-top: 10px;
    }
  }
  .pickcar-list {
    margin-bottom: 50px;
  }
  .vehicle_part {
    ul {
      &:before,
      &:after {
        display: none;
      }
    }
    .pickcar-list {
      ul.main {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        li {
          width: 50%;
          flex-grow: 1;
          &:first-of-type {
            width: 100%;
          }
          &:last-of-type {
            width: 40%;
          }
          .content-4 {
            padding: 0;
            h3.text-center {
              padding: 5px 0;
            }
            ul.info {
              li {
                margin: 0;
                padding: 0;
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}
