.center-button {
  margin-top: 20px;
  text-align: center;
}

.ui-button {
  border: none;
  min-width: 140px;
  padding: 10px 30px;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  background-color: gray;
  border-radius: 3px;

  &.btn-cancel {
    color: #211f1f;
    margin-left: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
  }

  &.btn-price {
    border-radius: 3px;
  }

  &.red {
    background-color: #eb273d;
  }

  &.white {
    border: 1px solid #ccc;
    background-color: #fff;
    color: #211f1f !important;
  }
}

.ui-state-hover,
.ui-state-focus,
.ui-button.ui-state-default {
    background: #fff;
    box-shadow: none !important;
    border: 1px solid 1px solid #ccc !important;
}
.ui-button,
button.ui-button.ui-state-default,
.ui-button.ui-state-default {
    border: 1px solid 1px solid #ccc !important;
}
