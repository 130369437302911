#dashboard {
  .content {
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);

    .dashboard-sub-title {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .dashboard-description {
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 60px;
    }

    .section-booking {
      color: #fff;
      padding: 30px;
      background-color: gray;
      border: 1px solid gray;

      .dashboard-button {
        text-align: right;
      }
    }
  }
}

.dashboard-profile-item {
  padding: 15px 20px;
  color: #211f1f;
  border: 1px solid #ccc;

  &.noborder {
    border: none;
    padding: 5px 20px;

    .itemtext {
      display: inline-block;
      text-transform: uppercase;
    }
  }

  .itemcheckbox {
    width: 30px;
    margin-left: -5px;
    display: inline-block;
  }

  .itemtextcheckbox {
    margin-top: 2px;
    margin-left: 15px;
    display: inline-block;
  }

  .itemimage {
    width: 20px;
    margin-right: 15px;
    text-align: center;
    display: inline-block;

    img {
      height: auto;
      max-width: 100%;
      max-height: 30px;
    }
  }

  .itemtext {
    display: inline-block;
  }

  .itemvalue {
    float: right;
    text-align: right;
    font-weight: bold;
  }

  &.item-cars .itemimage {
    width: 30px;
    margin-left: -6px;
  }
}

.dashboard-header-item {
  padding-bottom: 20px;

  .text {
    font-weight: bold;
    line-height: 50px;
    color: #211f1f;
    text-transform: uppercase;
  }

  .ui-button {
    width: 185px;
    margin-left: 10px;
    padding: 10px 15px;
    font-size: 14px !important;
    text-transform: none !important;
  }
}

.dashboard-booking-item.item-last {
  border-bottom: 1px solid #ccc;
}

.dashboard-title {
  color: #3699dd;

  p {
    color: #666666;
    font-size: 16px;
    padding-top: 20px;
    font-weight: normal;
    text-transform: none;
  }
}

.dashboard-button {
  .message {
    color: red;
  }

  button {
    color: #fff;
    width: auto;
    height: 50px;
    padding: 0 30px;
    font-weight: bold;
    border: 1px solid #fff;
    text-transform: uppercase;
    background-color: gray;
  }

  a {
    font-weight: bold;
    border-radius: 30px;
    border: 1px solid #ccc;
    padding: 10px 16px 10px 20px;
    background-color: gray;
  }
}

.dashboard-address-item {
  font-size: 16px;
  border-top: 1px solid #ccc;
  padding: 10px 0 !important;

  .dashboard-address-image {
    padding: 0;
    line-height: 40px;

    img {
      width: 40px;
      height: 40px;
    }

    div {
      width: 60px;
      font-weight: bold;
      text-align: center;
      display: inline-block;
      text-transform: capitalize;
    }

    .home {
      color: #fff;
      background-color: gray;
    }

    .work {
      color: #fff;
      background-color: #00a99d;
    }
  }

  .dashboard-address-value {
    padding: 0;
    color: #666;
    text-align: right;
    font-weight: normal;
    text-transform: initial;

    button {
      height: auto;
      color: #808080;
      font-size: 16px;
      padding: 10px 15px;
      border: 1px solid #ccc;
      display: inline-block;
      background-color: #fff;

      &:hover {
        color: #666;
      }
    }
    
    a {
      cursor: pointer;
    }
  }

  &.item-last {
    border-bottom: 1px solid #ccc;
  }
}

.dashboard-sub-header {
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 5px;
  color: gray;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px !important;
}

@media (max-width: 767px) {
  .dashboard .content {
    .section-right {
      padding: 30px 10px;
      background-color: #fff;
    }

    .dashboard-header-item {
      font-size: 16px;

      a {
        font-size: 14px;
      }
    }

    .dashboard-profile-item {
      font-size: 14px;
    }

    .dashboard-sub-title {
      margin-bottom: 60px;
    }

    .ui-paginator-prev, .ui-paginator-next {
      display: none;
    }
  }

  .booking-table {
    .booking-table-customer, .booking-table-address, .booking-table-car, .booking-table-status {
      border-right: none;
    }

    .booking-table-pay, .booking-table-ref, .booking-table-car, .booking-table-status {
      text-align: center;
    }
  }

  .booking-navigate button {
    margin-bottom: 10px;
  }
}
