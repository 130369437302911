.ui-checkbox {
  cursor: pointer;
  line-height: 28px;
  position: relative;
  padding-left: 30px;
  margin-bottom: 18px;
  font-weight: normal;
}

.ui-checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 1px solid #d6d6d6;
}
.control__indicator.control__indicator_radio {
  border-radius: 50%;
}
.control__indicator.control__indicator_radio:after {
  left: 7px !important;
  width: 5px !important;
}

.control--radio .control__indicator {
  border-radius: 50%;
}

.ui-checkbox input:checked ~ .control__indicator {
  background: #2aa1c0;
}

.ui-checkbox:hover input:not([disabled]):checked ~ .control__indicator,
.ui-checkbox input:checked:focus ~ .control__indicator {
  background: #0e647d;
}

.ui-checkbox input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}

.ui-checkbox input:checked ~ .control__indicator:after {
  display: block;
}

.control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}

.control--radio .control__indicator:after {
  left: 6px;
  top: 6px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}

.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}

.ui-chkbox .ui-chkbox-box {
  width: 20px;
  height: 20px;
  border-radius: 0px;
  margin-right: 2px;
}

.ui-chkbox .ui-chkbox-icon {
  margin-left: 0px;
}

.ui-chkbox .ui-chkbox-label {
  margin-bottom: 0;
}

.checkbox {
  display: block;
  cursor: pointer;
  padding-top: 5px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 17px;
  color: #211f1f;
  font-size: 17px;
}
.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox .control__indicator {
  left: 0;
  top: 2px;
  width: 30px;
  height: 30px;
  background: #fff;
  position: absolute;
  border: 1px solid #3699dd;
}
.checkbox .control__indicator.radio {
  margin: 0;
  padding: 0;
  border-radius: 50%;
}
.checkbox .control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox input:checked ~ .control__indicator {
  background: #fff;
}
.checkbox input:checked ~ .control__indicator:after {
  display: block;
}
.checkbox.control--checkbox .control__indicator:after {
  top: 6px;
  left: 10px;
  width: 8px;
  height: 14px;
  transform: rotate(45deg);
  border: solid #0f76bb;
  border-width: 0 2px 2px 0;
}
