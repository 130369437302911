@import "items/ticket.scss";
@import "items/invoice.scss";
@import "items/tracking.scss";
@import "items/dashboard.scss";
@import "items/commission.scss";
@import "items/payment.page.scss";
@import "items/booking.info.scss";
@import "items/booking.table.scss";
@import "items/booking.detail.scss";
@import "items/update.address.scss";
@import "items/update.profile.scss";
@import "items/booking.history.scss";
@import "items/booking.navigate.scss";

.page-account {
  margin: 20px 0;
  min-height: 100vh;
  .section-right {
    background-color: #fff;
    border-left: 1px solid #ccc;

    .menuheader {
      background-color: gray;
    }

    .ui-paginator {
      padding: 0;
      border: none;
      background: #fff;
      margin-top: 10px !important;

      .ui-paginator-element span {
        height: 21px;
        padding: 1px 0;
      }
    }
  }

  .section-content {
    padding: 20px;
  }

  .section-booking-description {
    margin: 30px 0;
    font-size: 16px;
    text-align: center;
  }

  .menuheader {
    color: #fff;
    padding: 15px;
    border-top: none;
    font-weight: bold;
    text-transform: uppercase;
    background-color: gray;

    .menuimage {
      width: 50px;
      text-align: center;
      display: inline-block;
    }
  }

  .menuitem {
    padding: 10px;
    color: #211f1f;
    border-top: 1px solid #ccc;
    background-color: #f2f6f9;

    .menuimage {
      width: 50px;
      text-align: center;
      display: inline-block;
    }

    .menutext {
      display: inline-block;

      a {
        cursor: pointer;
        color: #211f1f;
      }
    }

    &.active,
    &:hover {
      font-weight: bold;
      border-left: 2px solid gray;
    }
  }
  
  .container {
    .content {
      .section-left,
      .section-right {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .page-account {
    &.container-fluid {
      padding: 0;
    }
    .container {
      .content {
        .section-left,
        .section-right {
          padding-left: 0;
          padding-right: 0;
        }
        .dashboard-header-item {
          .buttons {
            .ui-button {
              margin: 0;
              min-width: unset;
              width: fit-content;
              padding-left: 15px;
              padding-right: 15px;
              white-space: nowrap;
              &:first-child {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .section-content {
      padding: 10px;
    }
  }
}
