.footer {
    height: 48px;
    display: flex;
    color: #fff;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border-top: 2px solid #fff;
    p {
        margin: 0;
        padding: 0;
    }
}