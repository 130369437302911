.spinner {
  width: 70px;
  text-align: center;
  margin: 10px auto 0;

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  > div {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-block;
    background-color: #fff;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  &.black > div {
    background-color: #444 !important;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
    80%,
    100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
    80%,
    100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
