#payment-page {
    min-height: 100%;
    margin-bottom: 20px;
    &.loading {
        .container {
            .booking-content {
                display: flex;
                font-size: 24px;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .container {
        margin-top: 20px;
        background-color: #fff;
        padding: 30px 30px 30px 50px;
        .wizardt,
        .gateway {
            .item-float {
                .ui-button {
                    border-radius: 4px;
                    border: 1px solid #007bff;
                }
            }
        }
    }
    .title {
        margin: 0;
        color: #fff;
        font-size: 24px;
        padding-top: 50px;
        text-align: center;
        text-transform: uppercase;
    }
    .booking-content {
        padding: 0;
        min-height: 640px;
        padding-right: 30px;
        #pickcard {
            .nav-pills {
                margin-bottom: 10px;
            }
        }
    }
    .booking-right {
        padding: 0;
        margin-top: 40px;
        position: relative;
    }
}

@media (max-width: 767px) {
    .container {
        img {
            position: unset;
        }
    }
    #payment-page {
        margin: 0;
        padding: 0;
        .container {
            margin-top: 0;
            min-height: unset;
            padding: 0 10px 0 10px;
            .booking-right {
                margin: 0;
                padding: 0;
                margin-bottom: 140px;
                .mobile {
                    display: none !important;
                }
                .onlyPayment {
                    z-index: 0;
                    margin-top: 20px;
                    top: unset !important;
                    width: auto !important;
                    display: block !important;
                    position: relative !important;
                    .item-float,
                    .quote-confirm {
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        z-index: 11;
                        position: fixed;
                        background-color: #007acc;
                        .center-button {
                            margin-top: 0;
                            padding-bottom: 10px;
                            .ui-button {
                                max-width: 90%;
                            }
                        }
                    }
                    .quote-confirm {
                        padding: 10px 20px;
                        background-color: #51687f;
                    }
                }
            }
            .booking-content {
                margin: 0;
                padding: 0;
                min-height: unset;
                #pickcard,
                .section-item {
                    margin: 0;
                    padding: 0;
                    padding-top: 10px;
                }
                .form-group {
                    padding: 0;
                }
                .input-inline .nopadding {
                    z-index: 0;
                }
            }
        }
    }
}
