#bookinginfo-page {
  min-height: 90%;

  .title {
    margin: 0;
    color: #fff;
    font-size: 24px;
    padding-top: 50px;
    text-align: center;
    text-transform: uppercase;
  }

  #content-overlay {
    height: 220px;

    .overlay-color {
      opacity: 0.8;
    }
  }

  .container {
    padding: 30px;
    margin-top: 27px;
    background-color: #fff;
  }
}

@media (max-width: 767px) {
  #bookinginfo-page {
    margin-top: 50px;
  }
}
