::ng-deep {
    .MicrosoftMap {
        width: 100%;
        .as_img {
            max-height: 30px;
        }
        .as_container,
        .asOuterContainer {
            border-color: #cbcbcb;
            ul {
                padding: 0 !important;
                li {
                    &:hover {
                        background-color: #eee;
                    }
                }
            }
        }
        .as_container_search {
            width: 100% !important;
        }
    }
    .NavBar_MapTypeButtonContainerWrapper {
        display: none;
    }
}