.nav-item-profile {
    position: relative;

    .submenu {
        left: 15px;
        z-index: 1;
        width: 300px;
        display: none;
        text-align: left;
        border-radius: 5px;
        padding: 10px 20px;
        position: absolute;
        background-color: rgba($color: #0C0C0C, $alpha: 0.9);

        a {
            opacity: 0.8;
            color: #fff;
            display: block !important;
            padding: 5px 0 !important;
            line-height: 30px !important;

            &:hover {
                opacity: 1;
            }
        }

        &.inactive {
            display: none !important;
        }

        &:hover {
            display: block;
        }
    }

    &:hover {
        .submenu {
            display: block;
        }
    }

}

.nav-item-profile {
    .submenu {
        top: 33px;
        left: 0px;
        width: 180px;
    }
}

body.down .navbar {
    .nav-item-airport {
        .submenu {
            background-color: rgba($color: #0071ca, $alpha: 1);
        }
    }
}

@media (max-width: 1023px) {
    .nav-item-profile {
        display: none !important;
    }
}