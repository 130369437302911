.country-select .flag {
  height: 11px;
  min-width: 16px;
  overflow: hidden;
  width: 16px !important;
  text-overflow: ellipsis;
  margin: 0 6px 0 0 !important;
  background: url(../images/icons/countryflags.png);
}

.country-select .zw {
  background-position: 0 0;
}

.country-select .zm {
  background-position: -16px 0;
}

.country-select .za {
  background-position: 0 -11px;
}

.country-select .yt {
  background-position: -16px -11px;
}

.country-select .ye {
  background-position: -32px 0;
}

.country-select .ws {
  background-position: -32px -11px;
}

.country-select .wf {
  background-position: 0 -22px;
}

.country-select .vu {
  background-position: -32px -22px;
}

.country-select .vn {
  background-position: 0 -33px;
}

.country-select .vi {
  background-position: -16px -33px;
}

.country-select .vg {
  background-position: -32px -33px;
}

.country-select .ve {
  background-position: -48px 0;
}

.country-select .vc {
  background-position: -48px -11px;
}

.country-select .va {
  background-position: -48px -22px;
}

.country-select .uz {
  background-position: -48px -33px;
}

.country-select .uy {
  background-position: 0 -44px;
}

.country-select .us {
  background-position: -16px -44px;
}

.country-select .um {
  background-position: -16px -44px;
}

.country-select .ug {
  background-position: -32px -44px;
}

.country-select .ua {
  background-position: -48px -44px;
}

.country-select .tz {
  background-position: -64px 0;
}

.country-select .tw {
  background-position: -64px -11px;
}

.country-select .tv {
  background-position: -64px -22px;
}

.country-select .tt {
  background-position: -64px -33px;
}

.country-select .tr {
  background-position: -64px -44px;
}

.country-select .to {
  background-position: 0 -55px;
}

.country-select .tn {
  background-position: -16px -55px;
}

.country-select .tm {
  background-position: -32px -55px;
}

.country-select .tl {
  background-position: -48px -55px;
}

.country-select .tk {
  background-position: -64px -55px;
}

.country-select .tj {
  background-position: 0 -66px;
}

.country-select .th {
  background-position: -16px -66px;
}

.country-select .tg {
  background-position: -32px -66px;
}

.country-select .tf {
  background-position: -48px -66px;
}

.country-select .td {
  background-position: -64px -66px;
}

.country-select .tc {
  background-position: -80px 0;
}

.country-select .sz {
  background-position: -80px -11px;
}

.country-select .sy {
  background-position: -80px -22px;
}

.country-select .sx {
  background-position: -80px -33px;
}

.country-select .sv {
  background-position: -80px -44px;
}

.country-select .st {
  background-position: -80px -55px;
}

.country-select .ss {
  background-position: -80px -66px;
}

.country-select .sr {
  background-position: 0 -77px;
}

.country-select .so {
  background-position: -16px -77px;
}

.country-select .sn {
  background-position: -32px -77px;
}

.country-select .sm {
  background-position: -48px -77px;
}

.country-select .sl {
  background-position: -64px -77px;
}

.country-select .sk {
  background-position: -80px -77px;
}

.country-select .si {
  background-position: -96px 0;
}

.country-select .sh {
  background-position: -96px -11px;
}

.country-select .sg {
  background-position: -96px -22px;
}

.country-select .se {
  background-position: -96px -33px;
}

.country-select .sd {
  background-position: -96px -44px;
}

.country-select .sc {
  background-position: -96px -66px;
}

.country-select .sb {
  background-position: -96px -77px;
}

.country-select .sa {
  background-position: 0 -88px;
}

.country-select .rw {
  background-position: -16px -88px;
}

.country-select .ru {
  background-position: -32px -88px;
}

.country-select .rs {
  background-position: -48px -88px;
}

.country-select .ro {
  background-position: -64px -88px;
}

.country-select .qa {
  background-position: -80px -88px;
}

.country-select .py {
  background-position: -96px -88px;
}

.country-select .pw {
  background-position: 0 -99px;
}

.country-select .pt {
  background-position: -16px -99px;
}

.country-select .ps {
  background-position: -32px -99px;
}

.country-select .pr {
  background-position: -48px -99px;
}

.country-select .pn {
  background-position: -64px -99px;
}

.country-select .pm {
  background-position: -80px -99px;
}

.country-select .pl {
  background-position: -96px -99px;
}

.country-select .pk {
  background-position: -112px 0;
}

.country-select .ph {
  background-position: -112px -11px;
}

.country-select .pg {
  background-position: -112px -22px;
}

.country-select .pf {
  background-position: -112px -33px;
}

.country-select .pe {
  background-position: -112px -44px;
}

.country-select .pa {
  background-position: -112px -55px;
}

.country-select .om {
  background-position: -112px -66px;
}

.country-select .nz {
  background-position: -112px -77px;
}

.country-select .nu {
  background-position: -112px -88px;
}

.country-select .nr {
  background-position: -112px -99px;
}

.country-select .no {
  background-position: 0 -110px;
}

.country-select .bv {
  background-position: 0 -110px;
}

.country-select .sj {
  background-position: 0 -110px;
}

.country-select .nl {
  background-position: -16px -110px;
}

.country-select .ni {
  background-position: -32px -110px;
}

.country-select .ng {
  background-position: -48px -110px;
}

.country-select .nf {
  background-position: -64px -110px;
}

.country-select .ne {
  background-position: -80px -110px;
}

.country-select .nc {
  background-position: -96px -110px;
}

.country-select .na {
  background-position: -112px -110px;
}

.country-select .mz {
  background-position: -128px 0;
}

.country-select .my {
  background-position: -128px -11px;
}

.country-select .mx {
  background-position: -128px -22px;
}

.country-select .mw {
  background-position: -128px -33px;
}

.country-select .mv {
  background-position: -128px -44px;
}

.country-select .mu {
  background-position: -128px -55px;
}

.country-select .mt {
  background-position: -128px -66px;
}

.country-select .ms {
  background-position: -128px -77px;
}

.country-select .mr {
  background-position: -128px -88px;
}

.country-select .mq {
  background-position: -128px -99px;
}

.country-select .mp {
  background-position: -128px -110px;
}

.country-select .mo {
  background-position: 0 -121px;
}

.country-select .mn {
  background-position: -16px -121px;
}

.country-select .mm {
  background-position: -32px -121px;
}

.country-select .ml {
  background-position: -48px -121px;
}

.country-select .mk {
  background-position: -64px -121px;
}

.country-select .mh {
  background-position: -80px -121px;
}

.country-select .mg {
  background-position: -96px -121px;
}

.country-select .me {
  background-position: 0 -132px;
  height: 12px;
}

.country-select .md {
  background-position: -112px -121px;
}

.country-select .mc {
  background-position: -128px -121px;
}

.country-select .ma {
  background-position: -16px -132px;
}

.country-select .ly {
  background-position: -32px -132px;
}

.country-select .lv {
  background-position: -48px -132px;
}

.country-select .lu {
  background-position: -64px -132px;
}

.country-select .lt {
  background-position: -80px -132px;
}

.country-select .ls {
  background-position: -96px -132px;
}

.country-select .lr {
  background-position: -112px -132px;
}

.country-select .lk {
  background-position: -128px -132px;
}

.country-select .li {
  background-position: -144px 0;
}

.country-select .lc {
  background-position: -144px -11px;
}

.country-select .lb {
  background-position: -144px -22px;
}

.country-select .la {
  background-position: -144px -33px;
}

.country-select .kz {
  background-position: -144px -44px;
}

.country-select .ky {
  background-position: -144px -55px;
}

.country-select .kw {
  background-position: -144px -66px;
}

.country-select .kr {
  background-position: -144px -77px;
}

.country-select .kp {
  background-position: -144px -88px;
}

.country-select .kn {
  background-position: -144px -99px;
}

.country-select .km {
  background-position: -144px -110px;
}

.country-select .ki {
  background-position: -144px -121px;
}

.country-select .kh {
  background-position: -144px -132px;
}

.country-select .kg {
  background-position: 0 -144px;
}

.country-select .ke {
  background-position: -16px -144px;
}

.country-select .jp {
  background-position: -32px -144px;
}

.country-select .jo {
  background-position: -48px -144px;
}

.country-select .jm {
  background-position: -64px -144px;
}

.country-select .je {
  background-position: -80px -144px;
}

.country-select .it {
  background-position: -96px -144px;
}

.country-select .is {
  background-position: -112px -144px;
}

.country-select .ir {
  background-position: -128px -144px;
}

.country-select .iq {
  background-position: -144px -144px;
}

.country-select .io {
  background-position: -160px 0;
}

.country-select .in {
  background-position: -160px -11px;
}

.country-select .im {
  background-position: -160px -22px;
  height: 9px;
}

.country-select .il {
  background-position: -160px -31px;
}

.country-select .ie {
  background-position: -160px -42px;
}

.country-select .id {
  background-position: -160px -53px;
}

.country-select .hu {
  background-position: -160px -64px;
}

.country-select .ht {
  background-position: -160px -75px;
}

.country-select .hr {
  background-position: -160px -86px;
}

.country-select .hn {
  background-position: -160px -97px;
}

.country-select .hk {
  background-position: -160px -108px;
}

.country-select .gy {
  background-position: -160px -119px;
}

.country-select .gw {
  background-position: -160px -130px;
}

.country-select .gu {
  background-position: -160px -141px;
}

.country-select .gt {
  background-position: 0 -155px;
}

.country-select .gs {
  background-position: -16px -155px;
}

.country-select .gr {
  background-position: -32px -155px;
}

.country-select .gq {
  background-position: -48px -155px;
}

.country-select .gp {
  background-position: -64px -155px;
}

.country-select .gn {
  background-position: -80px -155px;
}

.country-select .gm {
  background-position: -96px -155px;
}

.country-select .gl {
  background-position: -112px -155px;
}

.country-select .gi {
  background-position: -128px -155px;
}

.country-select .gh {
  background-position: -144px -155px;
}

.country-select .gg {
  background-position: -160px -155px;
}

.country-select .ge {
  background-position: -176px 0;
}

.country-select .gd {
  background-position: -176px -11px;
}

.country-select .gb {
  background-position: -176px -22px;
}

.country-select .ga {
  background-position: -176px -33px;
}

.country-select .fr {
  background-position: -176px -44px;
}

.country-select .gf {
  background-position: -176px -44px;
}

.country-select .re {
  background-position: -176px -44px;
}

.country-select .mf {
  background-position: -176px -44px;
}

.country-select .bl {
  background-position: -176px -44px;
}

.country-select .fo {
  background-position: -176px -55px;
}

.country-select .fm {
  background-position: -176px -66px;
}

.country-select .fk {
  background-position: -176px -77px;
}

.country-select .fj {
  background-position: -176px -88px;
}

.country-select .fi {
  background-position: -176px -99px;
}

.country-select .eu {
  background-position: -176px -121px;
}

.country-select .et {
  background-position: -176px -132px;
}

.country-select .es {
  background-position: -176px -143px;
}

.country-select .er {
  background-position: -176px -154px;
}

.country-select .eh {
  background-position: -16px -166px;
}

.country-select .eg {
  background-position: -32px -166px;
}

.country-select .ee {
  background-position: -48px -166px;
}

.country-select .ec {
  background-position: -64px -166px;
}

.country-select .dz {
  background-position: -80px -166px;
}

.country-select .do {
  background-position: -96px -166px;
}

.country-select .dm {
  background-position: -112px -166px;
}

.country-select .dk {
  background-position: -128px -166px;
}

.country-select .dj {
  background-position: -144px -166px;
}

.country-select .de {
  background-position: -160px -166px;
}

.country-select .cz {
  background-position: -176px -166px;
}

.country-select .cy {
  background-position: 0 -177px;
}

.country-select .cx {
  background-position: -16px -177px;
}

.country-select .cw {
  background-position: -32px -177px;
}

.country-select .cv {
  background-position: -48px -177px;
}

.country-select .cu {
  background-position: -64px -177px;
}

.country-select .cs {
  background-position: -80px -177px;
}

.country-select .cr {
  background-position: -96px -177px;
}

.country-select .co {
  background-position: -112px -177px;
}

.country-select .cn {
  background-position: -128px -177px;
}

.country-select .cm {
  background-position: -144px -177px;
}

.country-select .cl {
  background-position: -160px -177px;
}

.country-select .ck {
  background-position: -176px -177px;
}

.country-select .ci {
  background-position: -192px 0;
}

.country-select .cg {
  background-position: -192px -11px;
}

.country-select .cf {
  background-position: -192px -22px;
}

.country-select .cd {
  background-position: -192px -33px;
}

.country-select .cc {
  background-position: -192px -44px;
}

.country-select .ca {
  background-position: -192px -66px;
}

.country-select .bz {
  background-position: -192px -77px;
}

.country-select .by {
  background-position: -192px -88px;
}

.country-select .bw {
  background-position: -192px -99px;
}

.country-select .bt {
  background-position: -192px -110px;
}

.country-select .bs {
  background-position: -192px -121px;
}

.country-select .br {
  background-position: -192px -132px;
}

.country-select .bq {
  background-position: -192px -143px;
}

.country-select .bo {
  background-position: -192px -154px;
}

.country-select .bn {
  background-position: -192px -165px;
}

.country-select .bm {
  background-position: -192px -176px;
}

.country-select .bj {
  background-position: 0 -188px;
}

.country-select .bi {
  background-position: -16px -188px;
}

.country-select .bh {
  background-position: -32px -188px;
}

.country-select .bg {
  background-position: -48px -188px;
}

.country-select .bf {
  background-position: -64px -188px;
}

.country-select .be {
  background-position: -80px -188px;
}

.country-select .bd {
  background-position: -96px -188px;
}

.country-select .bb {
  background-position: -112px -188px;
}

.country-select .ba {
  background-position: -128px -188px;
}

.country-select .az {
  background-position: -144px -188px;
}

.country-select .ax {
  background-position: -160px -188px;
}

.country-select .aw {
  background-position: -176px -188px;
}

.country-select .au {
  background-position: -192px -188px;
}

.country-select .hm {
  background-position: -192px -188px;
}

.country-select .at {
  background-position: -208px 0;
}

.country-select .as {
  background-position: -208px -11px;
}

.country-select .ar {
  background-position: -208px -22px;
}

.country-select .ao {
  background-position: -208px -33px;
}

.country-select .an {
  background-position: -208px -44px;
}

.country-select .am {
  background-position: -208px -55px;
}

.country-select .al {
  background-position: -208px -66px;
}

.country-select .ai {
  background-position: -208px -77px;
}

.country-select .ag {
  background-position: -208px -88px;
}

.country-select .af {
  background-position: -208px -99px;
}

.country-select .ae {
  background-position: -208px -110px;
}

.country-select .ad {
  background-position: -208px -121px;
}

.country-select .np {
  background-position: -208px -132px;
}

.country-select .ch {
  background-position: -208px -143px;
}

.country-select {
  width: 100%;
  position: relative;
  display: inline-block;
}

.country-select * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.country-select .hide {
  display: none;
}

.country-select .v-hide {
  visibility: hidden;
}

.country-select input {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 34px !important;
}

.country-select .flag-dropdown {
  top: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
}

.country-select .flag-dropdown:hover {
  cursor: pointer;
}

.country-select input[disabled] + .flag-dropdown:hover {
  cursor: default;
}

.country-select input[disabled] + .flag-dropdown:hover .selected-flag {
  background-color: transparent;
}

.country-select .selected-flag {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.country-select .selected-flag:hover {
  background-color: transparent;
}

.country-select .selected-flag .flag:hover {
  opacity: 1;
  background-color: transparent;
}

.country-select .country-list {
  list-style: none;
  position: absolute;
  z-index: 2;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #ccc;
  max-height: 150px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  top: 50px;
}

.country-select .country-list .flag {
  display: inline-block;
}

.country-select .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.country-select .country-list .country {
  padding: 0px 12px;
  font-size: 16px;
  display: flex;
  overflow: hidden;
  align-items: center;
  white-space: nowrap;
}

.country-select .country-list .country .dial-code {
  color: #999;
}

.country-select .country-list .country.highlight {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
}

.country-select .country-list .country-name {
  overflow: hidden;
  margin-right: 6px;
  text-overflow: ellipsis;
}

.country-select.inside .flag-dropdown {
  padding: 1px;
}

.country-select.inside input[type="text"],
.country-select.inside input[type="tel"] {
  padding-left: 12px;
  margin-left: 0;
}

.country-select.outside input[type="text"],
.country-select.outside input[type="tel"] {
  border: none;
  margin-left: 38px;
}
