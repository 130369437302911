.country-select {
    .flag-dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .country-select {
            width: 40px;
            text-align: center;
        }
        .glyphicon {
            margin-right: 0;
            padding-right: 0 !important;
        }
    }
}
