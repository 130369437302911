#pickup {
    .add-info {
        margin-top: 20px;
        font-weight: bold;
        margin-bottom: 30px;
        font-size: 14px !important;
        .add-info-via {
            padding: 0;
            float: right;
            line-height: 48px;
            text-align: right;
        }
        .add-info-more {
            padding: 0;
            float: left;
            line-height: 30px;
            .nav-pills {
                display: block !important;
            }
        }
        .add-location {
            border: none;
            color: gray;
            font-weight: bold;
            background-color: transparent;
            img {
                width: 26px;
                height: 26px;
            }
        }
    }
    .ui-checkbox {
        margin-bottom: 0;
    }
    .flight-section {
        margin-top: -1px;
        border: 1px solid #ccc;
        border-radius: 0 0 4px 4px;
        border-top: none !important;
        padding: 10px 10px 15px 10px;

        .form {
            margin: 0;
            padding: 0;
        }

        .col-sm-6 {
            padding: 0 7px;
        }

        .ui-control {
            margin-top: 0;

            .ui-input {
                outline: none;
                padding-left: 12px !important;
            }
        }
    }
    .ui-nav-return ul {
        border: none !important;
        li {
            cursor: pointer;
            border-radius: 0;
            margin-right: 5px;
            text-align: center;
            padding: 8px 25px;
            width: auto !important;
            border: 1px solid #ccc !important;
            &:last-of-type {
                margin: 0;
            }
            a {
                padding: 0;
                color: gray;
                font-size: 14px;
                font-weight: bold;
                background-color: transparent;
                img {
                    width: 24px;
                    margin-right: 10px;
                }
            }
            &.active {
                color: #fff;
                background-color: gray;
                border: 1px solid gray !important;
                a {
                    color: #fff;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    #pickup {
        .add-info {
            font-size: 14px !important;
            .add-info-via {
                text-align: left;
                margin-bottom: 15px;
            }
        }
        .ui-nav-return ul li {
            padding: 4px 12px;
        }
    }
}