#pickfloat {
    padding: 0;
    width: 100%;
    display: block;
    position: absolute;
    background-color: rgba(16, 22, 28, 0.82);

    &.onlyPayment {
        padding-top: 20px;
    }

    ul {
        list-style: none;
    }

    .header {
        margin: 0;
        color: #fff;
        line-height: 50px;
        text-align: center;
        text-transform: uppercase;
        border-bottom: 1px solid #565656;
    }

    .ui-button {
        min-width: 0;
        height: 50px;
        border-radius: 0;
        padding: 10px 20px;
    }

    .button-promo-code {
        width: 96%;
        color: #fff;
        height: 40px;
        outline: none;
        font-weight: bold;
        border-radius: 20px;
        border: 1px solid #7e7e90;
        text-transform: uppercase;
        background-color: transparent;
    }

    .button-booking {
        width: 100%;
        height: 50px;
        color: black;
        border: none;
        outline: none;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        background-color: #e6d918;
    }

    .price {
        color: #fff;
        line-height: 30px;
        text-align: center;
    }

    .price-return {
        color: #fff;
        display: block;
        text-align: center;

        img {
            margin-right: 5px;
        }
    }

    .timeline {
        margin: 0;
        padding: 0;
        margin-left: -2px;
        margin-bottom: 5px;

        li {
            padding: 0;

            .timeline-badge {
                top: 4px;
                left: 12px;
                width: 22px;
                height: 22px;

                &.active:before {
                    top: 4px;
                    left: 8px;
                    width: 6px;
                    content: "";
                    height: 12px;
                    border: solid #fff;
                    position: absolute;
                    transform: rotate(45deg);
                    border-width: 0 2px 2px 0;
                }
            }
        }

        &:before {
            top: 15px;
            bottom: 20px;
        }

        .item-timeline {
            font-size: 14px;
            text-align: left;
            margin-left: 20px;
            padding: 3px 20px;

            .title {
                font-size: 14px;
                font-weight: bold;
                color: gray;
                text-transform: uppercase;
            }

            .text {
                color: #fff;

                &.paddingleft {
                    padding-left: 30px;
                }

                img {
                    margin-top: -4px;
                }
            }

            .image {
                width: 25px;
            }

            span,
            a,
            div {
                color: #fff;
            }
        }
    }

    .panel {
        box-shadow: none;
        margin-bottom: 0;
        border-radius: unset;
        background-color: transparent;

        &.panel-promo {
            padding: 0 5px;
            border-top: 1px solid #7a7c7c;
            border-bottom: 1px solid #7a7c7c;
        }

        span,
        a,
        div {
            color: #fff;
        }
    }

    .panel-title {
        font-weight: bold;
        text-transform: uppercase;
    }

    .panel-heading {
        background-color: #f1f5f8;
    }

    #promo-code-input .ui-invalid {
        width: 150%;
    }

    #map-float {
        height: 180px;
        display: block;
        overflow: hidden;
        position: relative;
        margin: 20px 20px 10px 20px;
    }

    .item-float {
        .text {
            margin: 0;
            color: #fff;
        }

        .center-alt {
            padding: 10px 20px 20px 20px;
        }
    }
}

#pickfloat-bottom {
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    z-index: 10;
    width: 100%;
    display: none;
    position: fixed;
    background-color: rgba(16, 22, 28, 0.82);

    .item-float {
        width: 100%;
        padding: 8px 0 4px 0;

        .item-float-column {
            padding: 0;
            color: #fff;
            padding: 2px 0;
            cursor: pointer;
            text-align: center;
            border-right: 1px solid #80B4DB;

            img {
                max-height: 20px;
            }

            &.item-last {
                border-right: none;
            }
        }

        .price {
            color: #fff;
            font-weight: bold;
        }

        .price-return {
            color: #fff;
        }

        .promo-code {
            padding: 5px 0;
            text-align: left;

            a {
                color: #fff;
            }

            .glyphicon {
                top: 3px;
                color: #fff;
            }
        }

        .center-button {
            color: #fff;
            width: 180px;
            margin: 0 auto;
            line-height: 50px;
            text-align: center;
            background-color: #808080;

            .ui-button {
                padding: 0;
                min-width: 0;
                border: none;
                outline: none !important;
                background-color: transparent;
            }

            .time-expried {
                margin-top: -6px;
            }
        }
    }
}

@media (max-width: 768px) {
    #pickfloat {
        display: none;
    }

    #pickfloat-bottom {
        display: block;

        &.disabled {
            display: none;
            pointer-events: none;
        }
    }
}