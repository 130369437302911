#modalFlightSearch {
  .modal-content {
    width: 842px;
  }
  .modal-body {
    .row {
      margin: 0 -20px;
      .ui-control {
        margin-left: 10px;
        margin-right: 10px;
        .ui-datepicker {
          left: 0;
          top: 48px;
          position: absolute;
        }
        .country-select {
          overflow: auto;
          position: absolute;
          border: 1px solid #ccc;
          &.inside .flag-dropdown {
            padding: 0;
          }
        }
      }
    }
    .result {
      margin: 20px 0 0 0;
      border-radius: 6px;
      border: 1px solid #e8e8e8;
      .inner {
        width: 100%;
        margin: auto;
        padding: 10px;
        max-width: 600px;
        a {
          cursor: pointer;
          margin-left: 30px;
          color: rgba(16, 72, 188, 0.8);
          &:hover {
            color: rgba(16, 72, 188, 1);
          }
        }
        ul {
          list-style: none;
          margin-left: 20px;
          li {
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 6px;
            background-color: transparent;
            &:hover {
              background-color: #e8e8e8;
            }
          }
        }
        .desc {
          margin-left: 30px;
        }
        .ui-control {
          display: flex;
          margin-left: 30px;
          .ui-input-inner {
            flex-grow: 1;
            .ui-input {
              border-right: none;
              border-radius: 6px 0 0 6px;
            }
          }
          .ui-button-ok {
            font-size: 16px;
            min-width: 70px;
            border-radius: 0;
            text-transform: none;
            background-color: rgba($color: red, $alpha: 0.9);
            &:hover {
              background-color: rgba($color: red, $alpha: 1);
            }
          }
          .ui-button-cancel {
            font-size: 16px;
            min-width: 80px;
            text-transform: none;
            background-color: #fff;
            border-radius: 0 6px 6px 0;
            border: 1px solid #e8e8e8;
            color: rgba(16, 72, 188, 1);
            border-left: none;
            &:hover {
              background-color: #e8e8e8;
            }
          }
        }
      }
    }
  }
}

#modalQuotation {
  .control {
    position: relative;

    &.mobile button {
      top: 1px;
      right: 1px;
      width: 38px;
      height: 100%;
      border: none;
      outline: none;
      position: absolute;
      background-color: transparent;
    }
  }

  .ui-button {
    width: auto;
    margin-top: 5px;
  }
}

#modalDateTime {
  z-index: 9999 !important;
}

#modalSignIn {
  .modal-content {
    .col-sm-6 {
      padding-left: 8px;
      padding-right: 8px;

      .ui-control {
        margin: 0 0 20px 0;
      }
    }
  }
}

#modalMenu {
  margin-top: 81px;
  background-color: #fff;
  z-index: 9999 !important;
  border-top: 1px solid #ccc;

  .mobile-menu li {
    margin: 15px;

    a {
      padding: 0;
      margin: 0;
      color: #063d62;
      font-size: 14px;
      margin-left: 5px;
    }
  }
}

.modal {
  padding-left: 0 !important;
  overflow: hidden !important;
  padding-right: 0 !important;
}

.modal-content {
  padding: 0;
  border-radius: 0;
  padding-top: 10px;
  padding-bottom: 20px;
  border: 1px solid #ccc;

  .modal-header {
    color: #808080;
    font-size: 18px;
    font-weight: bold;

    button.close {
      margin: 0;
      top: -15px;
      width: 30px;
      height: 30px;
      color: white;
      opacity: 0.5;
      right: -15px;
      position: absolute;
      font-weight: normal;
      border-radius: 50px;
      font-family: rocobo;
      border: 2px solid #fff;
      background-color: #000;
      outline: none !important;

      &:hover {
        opacity: 0.7;
        color: white;
        background-color: #000;
      }
    }

    .modal-title {
      text-align: center;
      text-transform: uppercase;
      background-color: transparent;

      img {
        width: 30px;
        height: 30px;
        margin: -3px 5px 0 -5px;
      }
    }

    .modal-sub-title {
      text-align: center;
      font-weight: normal;
    }

    .signin {
      cursor: pointer;
      text-align: center;
      border-right: 2px solid #555;

      &:hover,
      &.active {
        color: #063e63;
      }
    }

    .signup {
      cursor: pointer;
      text-align: center;

      &:hover,
      &.active {
        color: #063e63;
      }
    }
  }

  .modal-body {
    padding: 30px;
    color: #606060;
    font-size: 14px;
  }

  .modal-link {
    font-weight: normal;
    text-transform: none;
    color: #606060;
    font-size: 14px;

    a {
      cursor: pointer;
      text-decoration: none;
      color: #203f63;
      font-size: 14px;
    }
  }
}

.card-row-info {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
  background-color: gray;

  .price {
    margin: 0;
    padding: 0;
    padding-top: 14px;

    img {
      width: 12px;
      margin-top: -22px;
      margin-right: -5px;
    }
  }

  .card-paypal {
    text-align: right;

    button {
      padding: 0;
      width: 115px;
      height: auto;
      margin-top: 10px !important;
      background-color: transparent;

      img {
        border: 0px;
        width: 100%;
        outline: none;
        display: block;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 700px) {
  .modal-dialog .modal-content {
    width: initial;
    max-width: 100%;
  }
}

@media (max-width: 567px) {
  .modal {
    overflow-y: auto !important;
  }

  .modal-dialog {
    margin: 0;
    width: 100%;

    .modal-content {
      width: initial;

      .modal-header button.close {
        top: 0;
        right: 0;
      }
    }
  }

  .card-row-info {
    div {
      color: #fff !important;
    }

    .card-paypal {
      text-align: center;

      button {
        width: 150px;
        margin-bottom: 10px;
      }
    }
  }
}

@media (min-width: 768px) {
  .modal {
    overflow-y: auto !important;
  }

  #modalCard .modal-dialog {
    width: 700px;
  }
}
