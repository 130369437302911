#bookinghistory .content {
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);

    .control {
        float: none;
        margin: auto;
        padding: 5px 20px;

        span {
            font-size: 16px;
        }
    }

    .booking-table {
        .booking-table-ref {
            padding: 10px 5px !important;
        }
    }

    .bookinghistory-title {
        font-size: 16px;
        font-weight: bold;
        padding: 10px 20px;
        border: 1px solid #ccc;
        background-color: #f2f6f6;

        .bookinghistory-icon {
            float: right;
            color: gray;
            text-align: right;
        }

        .bookinghistory-dropdown {
            margin: 0;
            padding: 0;
            float: right;
            line-height: 25px;
            text-align: right;
        }

        &.history {
            line-height: 50px;
            padding: 0 0 0 20px;
        }
    }

    .bookinghistory-search {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        justify-content: flex-end;
        .ui-input {
            width: 100%;
            height: 40px;
            padding: 6px 12px;
        }
        .ui-button {
            padding: 0;
            height: 40px;
            border: none;
            outline: none;
            font-size: 16px;
            margin-left: 5px;
            text-transform: none !important;
        }
    }

    .bookinghistory-booking-item {
        margin: 0 30px;
        font-size: 16px;
        padding-bottom: 15px;
    }
}

@media (max-width: 567px) {
    .bookinghistory .content {
        padding: 30px 0px;

        .ui-paginator-prev,
        .ui-paginator-next {
            display: none;
        }

        .bookinghistory-title,
        .dashboard-booking-description {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    .bookinghistory-search {
        .ui-button {
            width: 100px;
            min-width: unset;
        }
    }
}