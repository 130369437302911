@import "items/icon.scss";
@import "items/panel.scss";
@import "items/button.scss";
@import "items/country.scss";
@import "items/control.scss";
@import "items/textbox.scss";
@import "items/spinner.scss";
@import "items/checkbox.scss";
@import "items/datetime.scss";
@import "items/dropdown.scss";
@import "items/autocomplete.scss";
@import "items/editor.bing.map.scss";
.stripe-wapper {
    input,
    .StripeElement {
        width: 100%;
        height: 50px;
        border-radius: 0;
        color: #211f1f;
        line-height: 50px;
        padding-left: 12px;
        position: relative;
        background: #fff;
        border: 1px solid #ccc;
    }
}

//pre-submit errors
#card-errors {
    margin: 4px 0;
    border: 1px solid red;
}

.modal-body {
    .center-button {
        margin-top: 20px;
    }
}

.radio-option {
    display: flex;
    align-items: center;
    .text-input {
        margin-right: 10px;
        .ui-checkbox {
            font-weight: bold;
            margin-right: 5px;
        }
        span {
            font-size: small;
        }
    }
    .control--radio {
        margin-bottom: 0;
        font-weight: bold;
    }
}

.password-hint {
    font-size: small;
}

#btnConfirmMobileStep {
    border: none;
}

@media (max-width: 767px) {
    .radio-option {
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
    }
}