.add-options-content {
    .item {
        .popupOptions {
            .title {
                display: flex;
                margin-bottom: 10px;
                padding: 0 !important;
                font-size: 16px !important;
                color: #211f1f !important;
                justify-content: space-between;
                text-transform: none !important;

                &.title-center {
                    margin-top: 15px !important;
                }
            }

            .popupItem {
                .dropdownWapper {
                    .ui-dropdown-label {
                        padding-left: 60px !important;
                    }

                    .ui-icon:first-of-type {
                        padding-top: 6px !important;
                    }
                }
            }
        }
    }
}

.ui-vehicle-invalid {
    color: red;
    font-size: 16px;
    margin-top: 8px;
}

// @media (max-width: 567px) {
//     .add-options-content {
//         position: relative;
//         flex-direction: row;

//         .item {
//             width: 49%;
//             position: unset;

//             &:first-child {
//                 margin-bottom: 0;
//             }

//             .popupOptions {
//                 left: 0;
//                 right: 0;
//                 width: 100%;
//             }
//         }
//     }
// }