.ui-datepicker {
    .ui-header {
        a {

            &:hover,
            &.active {
                color: #fff;
                background-color: grey;
            }
        }
    }

    .ui-minutepicker-header {
        a {
            color: grey;
            border: 1px solid grey;

            &:hover,
            &.active {
                color: #fff;
                background-color: grey;
            }
        }
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        background-color: grey;
    }

    .ui-time-content {
        .ui-timepicker {
            div a {
                background-color: grey;
            }
        }
    }

    &.ui-widget {

        .ui-datepicker-calendar {
            td a.ui-state-active {
                color: #ffffff;
                background-color: grey;
            }

            thead th {
                color: grey;
            }
        }
    }

    .ui-footer {
        a {
            background-color: grey;
        }
    }
}