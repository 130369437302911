#pickflight {
  margin: 0;

  .ui-control {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;

    .col-xs-12 {
      padding: 10px 10px 0 10px;
    }

    .ui-checkbox {
      margin-bottom: 10px;
    }

    .ui-calendar .ui-datepicker {
      width: 200%;
    }

    input:disabled {
      opacity: .50;
      filter: Alpha(Opacity = 50);
      background-color: #f6f6f6;
    }
  }
}
