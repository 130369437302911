.booking-table {
  border: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;

  > [class*='col-'] {
    display: flex;
    flex-direction: column;
    padding: 8px !important;
    border-right: 1px solid #ccc;

    p {
      font-weight: bold;
    }

    &.item-last {
      border-right: none;

      span {
        color: gray;
      }
    }

    a {
      text-align: center;
    }
  }

  .booking-booker-name {
    background-color: #f6f6f6;
    border-right: none !important;
  }

  .booking-booker-ref {
    text-align: right;
    background-color: #f6f6f6;
    border-right: none !important;
  }

  .booking-table-message {
    z-index: 1;
    color: brown;
    margin-top: -10px;
    background-color: #f6f6f6;
    border-right: none !important;
    padding: 5px 10px 5px 10px !important;
  }

  .booking-table-status {
    text-align: center;

    p {
      color: red;
    }
  }
}
