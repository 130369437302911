#menus {
    top: 0;
    z-index: 10;
    width: 100%;
    height: 70px;
    text-align: right;
    color: darkgoldenrod;
    background-color: black;
    &.fixed {
        position: fixed;
    }
    .container {
        padding: 0;
        width: 100%;
        height: 100%;
        max-width: 1140px;
        .navbar-default {
            border: none;
            height: 100%;
            margin-bottom: 0;
            background-color: transparent;
            .container-fluid {
                height: 100%;
                display: flex;
                align-items: center;
            }
            .navbar-header {
                padding-top: 2px;
                .navbar-brand {
                    display: flex;
                    align-items: center;
                    img {
                        width: 135px;
                    }
                }
            }
            .navbar-toggle {
                border: none;
                &:hover {
                    border: none;
                }
            }
        }
    }
    .navbar-toggle {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #fff;
        img {
            width: 26px;
            display: block;
        }
        .icon-bar {
            margin-left: auto;
            margin-right: auto;
            background-color: #0E7DC1;
        }
    }
    .navbar-collapse {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        box-shadow: none !important;
    }
    .main-navbar {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .navbar-nav {
            margin: 0;
            padding: 0;
            display: flex;
            list-style: none;
            margin-left: 25px;
            text-align: center;
            align-items: center;
            &.mobile {
                display: none;
            }
            li {
                cursor: pointer;
                padding: 10px 5px;
                position: relative;
                transition: all 0.4s;
                display: inline-block;
                border-bottom: 3px solid transparent;
                &:hover {
                    border-bottom: 3px solid #fff;
                    .submenu {
                        opacity: 1;
                        z-index: 101;
                        pointer-events: all;
                    }
                }
                &.navbar-other {
                    display: none;
                }
                a {
                    padding: 0;
                    opacity: 1;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 600;
                    transition: all 0.4s;
                    text-transform: uppercase;
                    &.active,
                    &:hover,
                    &:focus {
                        color: #0e7dc1;
                    }
                }
            }
        }
    }
    .submenu {
        opacity: 0;
        z-index: 1;
        display: flex;
        color: #000;
        margin-top: 8px;
        text-align: left;
        padding: 10px 20px;
        position: absolute;
        width: fit-content;
        white-space: nowrap;
        pointer-events: none;
        flex-direction: column;
        background-color: #fff;
        border: 1px solid #ccc;
        a {
            color: #000 !important;
            font-size: 13px !important;
            font-weight: 600 !important;
            line-height: 26px !important;
            text-decoration: none !important;
            text-transform: unset !important;
            &:hover {
                color: #0e7dc1 !important;
            }
        }
    }
    .nav-item-profile {
        &:hover {
            border-bottom-color: transparent !important;
        }
        .nav-item-link {
            font-weight: 600;
            min-width: 120px;
            border-radius: 25px;
            font-size: 16px !important;
            background-color: #ffffff;
            color: #0e7dc1 !important;
            padding: 4px 30px 4px 30px !important;
            &:hover {
                color: #ffffff !important;
                background-color: #0e7dc1 !important;
            }
        }
    }
    #main-navbar-user {
        display: none;
    }
    &.affix {
        background-color: #000000;
    }
}

@media (max-width: 1023px) {
    #menus {
        height: auto;
        .container {
            padding: 0;
            height: unset;
            .navbar-default {
                height: fit-content;
                .container-fluid {
                    display: flex;
                    height: fit-content;
                    align-items: center;
                    flex-direction: column;
                    .navbar-header {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
        .navbar-toggle {
            display: flex;
        }
        .navbar-header {
            .navbar-brand {
                margin-left: 15px;
            }
        }
        .main-navbar {
            left: 0;
            right: 0;
            top: 50px;
            bottom: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            flex-direction: column;
            .navbar-nav {
                width: 100%;
                height: 100%;
                display: none;
                &.open {
                    margin: 0;
                    padding: 0;
                    z-index: 12;
                    margin-top: 0;
                    display: flex;
                    text-align: left;
                    position: absolute;
                    height: fit-content;
                    flex-direction: column;
                    background-color: #fff;
                    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
                    li {
                        margin: 0;
                        width: 100%;
                        display: block;
                        border-bottom: none;
                        padding: 4px 0 4px 25px;
                        &.nav-item-profile {
                            display: block !important;
                        }
                        a {
                            margin: 0;
                            padding: 0;
                            font-size: 14px;
                            color: #000000;
                            line-height: 25px;
                            display: inline-block;
                        }
                        p {
                            margin: 0;
                            padding: 0;
                            margin: 15px 0;
                            color: wheat;
                            font-size: 14px;
                            img {
                                width: 20px;
                                margin-top: -6px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
                li {
                    &.navbar-other {
                        display: block;
                    }
                }
            }
        }
    }
}