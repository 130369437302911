@import '../../../../assets/css/common.scss';
@import '../../../../assets/css/primeng.scss';
@import '../../../../assets/css/bootstrap.scss';
@import '../../../../assets/css/editor.card.scss';
@import '../../../../assets/css/editor.flags.scss';
@import '../../../../assets/css/editor.phone.scss';
@import '../../../../assets/css/editor.country.scss';

@import '../../../../assets/css/wizard.scss';
@import '../../../../assets/css/modal/modal.scss';
@import '../../../../assets/css/editor/editor.scss';
@import '../../../../assets/css/pages/account/account.scss';
@import '../../../../assets/css/pages/booking/booking.scss';

@import '../../../../assets/css/editor/more.options.scss';
@import '../../../../assets/css/editor/more.options.angular.scss';

@import 'items/menu.scss';
@import 'items/header.scss';
@import 'items/footer.scss';
@import 'items/pickup.scss';
@import 'items/submenu.scss';
@import 'items/pickfloat.scss';
@import 'items/dropdownmenu.scss';
@import 'items/datetime-angular.scss';
@import 'items/dropdown-angular.scss';

body {
    width: 100%;
    overflow-x: hidden;
    background-color: #d3ebf7;
    padding-right: 0 !important;
    font-family: 'DM Sans', sans-serif;
}

body .affix {
    position: initial !important;
}

@media screen and (max-width: 767px) {
    #app-icon {
        margin-bottom: 0px !important;
    }
}

@media (max-width: 567px) {
    .control.mobile .datetime input {
        appearance: none;
    }
}

.payment-message-error {
    font-size: initial;
    margin: 0 !important;
    color: red !important;
    font-weight: normal !important;
    text-transform: none !important;
}

.section-item {
    margin: 0 -20px;
    padding: 20px;
    background-color: #f9fafa;
}

@media (max-width: 375px) {
    .nav-pills>li+li {
        margin-left: 0 !important;
    }
}
@import 'items/color.scss';
