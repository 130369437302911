﻿.f1 {
    padding: 25px;
    background: #fff;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
}

    .f1 h3 {
        margin-top: 0;
        margin-bottom: 5px;
        text-transform: uppercase;
    }

.f1-steps {
    overflow: hidden;
    position: relative;
    text-align: center;
    margin-bottom: 15px;
}

.f1-progress {
    left: 0;
    top: 24px;
    width: 100%;
    height: 1px;
    background: #ddd;
    position: absolute;
}

.f1-progress-line {
    top: 0;
    left: 0;
    height: 1px;
    position: absolute;
    background: gray;
}

.f1-step {
    width: 25%;
    float: left;
    padding: 0 5px;
    position: relative;
}

.f1-step-icon {
    width: 40px;
    height: 40px;
    color: #fff;
    margin-top: 4px;
    background: #ddd;
    font-size: 16px;
    line-height: 40px;
    border-radius: 50%;
    display: inline-block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
}

    .f1-step-icon i {
        top: 13px !important;
    }

.f1-step.activated .f1-step-icon {
    color: gray;
    background: #fff;
    line-height: 38px;
    border: 1px solid gray;
}

.f1-step.active .f1-step-icon {
    width: 48px;
    height: 48px;
    margin-top: 0;
    font-size: 22px;
    line-height: 48px;
    background: gray;
}

.f1-step.activated:hover .f1-step-icon {
    width: 48px;
    height: 48px;
    margin-top: 0;
    cursor: pointer;
    font-size: 22px;
    background: gray;
    line-height: 48px;
    color: #fff !important;
}

.f1-step p {
    color: #ccc;
}

.f1-step.activated p {
    color: gray;
}

.f1-step.active p {
    color: gray;
}

.f1 fieldset {
    display: none;
    text-align: left;
}

.f1-buttons {
    text-align: right;
}

.f1 .input-error {
    border-color: gray;
}
