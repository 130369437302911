#pickcard {
    margin-top: 40px;

    .nav li {
        cursor: pointer;
        border-radius: 0;
        margin-right: 5px;
        text-align: center;
        padding: 10px 25px;
        border: 1px solid #ccc;

        &.active {
            color: #fff;
            border: 1px solid gray;
            background-color: gray;
            
            a {
              color: #fff;
            }
        }

        a {
            padding: 0;
            color: gray;
            font-weight: bold;
            background-color: transparent;

            img {
                width: 24px;
                margin-right: 10px;
            }
        }
    }
}

@media (max-width: 767px) {
    #pickcard {
      margin-bottom: 50px;
    }
  }