@import 'items/pickup.scss';
@import 'items/pickcar.scss';
@import 'items/timeline.scss';
@import 'items/pickcard.scss';
@import 'items/pickfloat.scss';
@import 'items/pickflight.scss';
@import 'items/pickcontact.scss';

#booking-page {
  min-height: 100%;
  margin-bottom: 20px;

  .title {
    margin: 0;
    color: #fff;
    font-size: 24px;
    padding-top: 50px;
    text-align: center;
    text-transform: uppercase;
  }

  .booking-content {
    padding: 0;
    min-height: 640px;
    padding-right: 30px;

    .booking-page-step1 {
      min-height: 650px;
    }

    .booking-page-step1,
    .booking-page-step2,
    .booking-page-step3,
    .booking-page-step4 {
      .list-inline {
        margin: 0;
        padding: 0;

        li {
          margin: 0;
          padding: 0;
          margin-left: 10px;
        }
      }
    }
  }

  .booking-right {
    padding: 0;
    position: relative;
  }

  #content-overlay {
    height: 220px;

    .overlay-color {
      opacity: 0.8;
    }
  }

  .container {
    margin-top: 27px;
    min-height: 800px;
    background-color: #fff;
    padding: 30px 30px 30px 50px;
  }

  .ui-header {
    margin-top: 0;
    margin-left: -5px;
    position: relative;
    margin-bottom: 50px;

    &:after {
      left: 0px;
      height: 2px;
      content: "";
      bottom: -20px;
      position: absolute;
      border-top: 0 solid transparent;
      border-left: 40px solid #f06d63;
    }
  }
}

@media (max-width: 767px) {
  #booking-page {
    background-color: #fff;

    .booking-content {
      min-height: unset;

      .booking-page-step1 {
        min-height: unset;
      }
    }
  }

  #booking-page {
    .booking-content {
      padding: 0;
    }

    .container {
      margin-top: 0;
      padding: 20px;
      min-height: unset;
    }
  }
}

@media (min-width: 768px) and (max-width: 1032px) {
  #booking-page {
    .container {
      width: 100%;
    }

    .booking-content {
      padding-right: 20px;
    }
  }
}