.ui-icon {
  top: 0;
  z-index: 0;
  width: 48px;
  float: right;
  height: 100%;
  border: none;
  opacity: 0.8;
  padding: 10px;
  display: flex;
  color: gray;
  text-indent: 0;
  cursor: pointer;
  max-height: 58px;
  position: absolute;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    display: block;
  }

  .ui-icon-remove {
    display: block;
    font-size: 16px;
    font-weight: bold;
  }

  .ui-icon-substract {
    padding-top: 0;
    display: block;
    font-size: 24px;
    font-weight: bold;
    margin-top: -14px;
  }

  &:hover {
    opacity: 1;
  }

  &.icon-first {
    left: 1px;
    display: block;
  }

  &.icon-last {
    right: 0px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-left: 1px solid transparent;
    
    &:hover {
      border-left: 1px solid #ccc;
    }

    .glyphicon {
      padding: 0;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }

  &.icon-second {
    right: 47px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ccc;
    border-left: 1px solid transparent;

    &:hover {
      border-left: 1px solid #ccc;
    }
  }
}
