.ui-panel {
  display: none;
  margin: 0 auto;
  padding: 20px 0;
  margin-top: 20px;
  max-width: 615px;
  text-align: center;
  position: relative;

  fieldset {
    border: 1px solid #ccc;

    .center-alt {
      margin-bottom: 20px;
    }
  }

  &.active {
    display: block;
  }

  .panel-title {
    display: inline-block;
  }
}
