@import 'variables';

.dropdown-menu {
  left: 0;
  top: 100%;
  float: left;
  z-index: 1000;
  display: none;
  padding: 0px 0;
  margin: 2px 0 0;
  font-size: 14px;
  min-width: 160px;
  text-align: left;
  list-style: none;
  position: absolute;
  border-radius: 4px;
  background-color: $backgroundColor;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  width: 100%;

  &.pull-right {
    right: 0;
    left: auto;
  }

  .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }

  > {
    li > a {
      clear: both;
      display: block;
      padding: 3px 20px;
      color: $textColor;
      font-weight: normal;
      white-space: nowrap;
      line-height: 1.42857143;

      &:hover, &:focus {
        color: $textColor;
        text-decoration: none;
      }
    }

    .active > a {
      outline: 0;
      color: $textColor;
      text-decoration: none;
      background-color: #337ab7;

      &:hover, &:focus {
        outline: 0;
        color: $textColor;
        text-decoration: none;
        background-color: #337ab7;
      }
    }

    .disabled > a {
      color: #777;

      &:hover, &:focus {
        color: #777;
      }

      &:hover, &:focus {
        text-decoration: none;
        cursor: not-allowed;
        background-color: transparent;
        background-image: none;
        filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
      }
    }
  }
}

.open > {
  .dropdown-menu {
    display: block;
  }

  a {
    outline: 0;
  }
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

@media (min-width: 768px) {
  .navbar-right {
    .dropdown-menu {
      right: 0;
      left: auto;
    }

    .dropdown-menu-left {
      right: auto;
      left: 0;
    }
  }
}
