.booking-navigate {
  color: #fff;
  text-align: center;
  padding: 20px 0 !important;
  background-color: gray;

  button {
    color: #fff;
    opacity: 0.8;
    width: 110px;
    outline: none;
    padding: 10px 0;
    font-weight: bold;
    display: inline-block;
    border: 1px solid #fff;
    background-color: gray;

    img {
      margin-right: 10px;
    }

    &:hover {
      opacity: 1;
    }
  }

  a {
    color: #fff;
    opacity: 0.8;
    font-weight: bold;
    line-height: 40px;

    &:hover {
      opacity: 1;
    }
  }
}
