.ui-dropdown {
    margin: 0;
    height: 50px;
    padding-left: 0;
    border: 1px solid #ccc;
    border-radius: 0;
}

.ui-dropdown .ui-dropdown-panel {
    top: 59px;
}

.ui-dropdown .ui-dropdown-trigger {
    top: -1px;
    margin: 0;
    padding: 0;
    right: -1px;
    width: 50px;
    height: 50px;
    text-align: center;
    border: 1px solid #ccc;
}

.ui-dropdown .ui-dropdown-trigger .glyphicon {
    opacity: 0.6;
    color: #929497;
    padding: 16px 18px;
}

.ui-dropdown .ui-dropdown-trigger .glyphicon:hover {
    opacity: 1;
}

.ui-dropdown .ui-dropdown-label {
    margin: 0;
    padding: 0;
    font-size: 16px;
    overflow: hidden;
    color: #211f1f;
    line-height: 48px;
    padding-right: 50px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
}

.ui-dropdown .ui-dropdown-label span:first-child {
    font-weight: bold;
    text-transform: uppercase;
}

.ui-dropdown .ui-dropdown-item {
    font-size: 16px;
    color: #606060;
    padding: 10px 5px;
    border: 1px solid transparent;

    img {
        width: 22px;
        height: 22px;
        margin-top: -4px;
        margin-left: 10px;
    }

    &.ui-state-highlight {
        border: 1px solid gray;
        background-color: #eeeeee;
    }

    &:hover {
        border: 1px solid gray;
        background-color: #effeff;
    }
}

.ui-dropdown .ui-dropdown-item span span:first-child {
    font-weight: bold;
    text-transform: uppercase;
}

.ui-dropdown .ui-dropdown-label {
    padding-left: 50px;

    img {
        width: 22px;
        height: 22px;
        margin-top: -4px;
        margin-left: 10px;
    }
}

.ui-dropdown.notpadding .ui-dropdown-label {
    padding-left: 12px;
}

.dropdown {
    margin: 0 auto;
    margin-bottom: 1em;
}

.dropdown dt a {
    width: 100%;
    height: 2.5em;
    display: block;
    border-radius: 3px;
    text-decoration: none;
}

.dropdown dt a:hover,
.dropdown dt a:active {
    border-color: #bdc3c7;
}

.dropdown dt span {
    display: block;
    padding: 0 1em;
    cursor: pointer;
}

.dropdown dt label {
    font-weight: normal !important;
}

.dropdown dd ul {
    margin: 0;
    top: 48px;
    padding: 0;
    z-index: 1;
    left: -1px;
    display: none;
    list-style: none;
    background: #fff;
    position: absolute;
    width: calc(100% + 2px);
    border: 1px solid #bdc3c7;
}

.dropdown dd ul li a {
    display: block;
    padding: 0 1em;
    cursor: pointer;
    line-height: 40px;
    text-decoration: none;
    img {
        width: 22px;
        height: 22px;
        margin-top: -4px;
        margin-left: 10px;
    }
}

.dropdown dd ul li a:hover {
    background-color: #186ba0;
}

.dropdown dd ul li a:hover label {
    color: #fff;
}

.dropdown dd label {
    cursor: pointer;
    font-weight: normal !important;
}

@media (max-width: 767px) {
    .ui-dropdown .ui-dropdown-label span {
        margin-right: 10px;
    }
}