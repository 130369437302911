.ui-input {
  width: 100%;
  outline: none;
  padding-left: 12px;
  position: relative;
  color: #211f1f;
  border: 1px solid #ccc;
  height: 50px;
  border-radius: 0;
}

.ui-input.note {
  margin-top: 10px;
}

.ui-input.airport {
  padding-right: 50px !important;
}

.ui-input.hasicon {
  padding-left: 50px !important;
}

input[disabled] {
  opacity: 1 !important;
  background-color: #ebebe4 !important;
}
