@import "_variables";

#pickfloat {
  background-color: $backgroundColor;

  &.onlyPayment {
      padding-top: 20px;
  }

  &.onlyPayment {
      padding-top: 20px;
  }

  .panel {
    a,
    div,
    span {
      color: $textColor;
    }
  }

  .price {
    color: $textColor;
  }

  .header {
    color: $textColor;
  }

  .timeline {
    .timeline-badge.active:before {
      border: solid $textColor;
    }

    .item-timeline {
      a,
      div,
      span,
      .text,
      .title {
        color: $textColor;
      }
    }
  }

  .item-float {
    .text {
      color: $textColor;
    }

    .center-button {
      color: $backgroundColor;

      button {
        color: $backgroundColor;
        background-color: $textColor;
      }
    }
  }

  .price-return {
    color: $textColor;
  }

  .button-promo-code {
    color: $textColor;
  }
}

#pickfloat-bottom {
  background-color: $backgroundColor;

  .item-float {
    .price {
      color: $textColor;
    }

    .promo-code {
      a,
      .glyphicon {
        color: $textColor;
      }
    }

    .price-return {
      color: $textColor;
    }

    .center-button {
      color: $backgroundColor;
      background-color: $textColor;

      button {
        color: $backgroundColor;
        border: none !important;
      }
    }

    .item-float-column {
      color: $textColor;
    }
  }
}

@media (max-width: 768px) {
    #pickfloat {
        display: none;
    }

    #pickfloat-bottom {
        display: block;

        &.disabled {
            display: none;
            pointer-events: none;
        }
    }
}
