#tracking,
#bookingdetail {
  .map {
    margin: 20px;
    height: 600px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  }

  .content {
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);

    .booking-notify {
      padding: 10px;
      text-align: center;
      margin-bottom: 10px;
      background-color: #f2f6f8;

      .itemtext {
        float: left;
        text-align: left;
      }

      .itemvalue {
        color: red;
      }
    }

    .booking-price {
      font-size: 20px;
      margin-top: 20px;
      padding-top: 10px;
      font-weight: bold;
      border-top: 1px solid #ccc;

      .itemtext {
        float: left;
        text-align: left;
      }

      .itemvalue {
        color: red;
      }
    }

    button {
      opacity: 0.8;
      width: 110px;
      min-width: 0;
      color: #fff;
      outline: none;
      padding: 10px 0;
      font-weight: bold;
      display: inline-block;
      border: 1px solid #fff;
      background-color: gray;

      img {
        margin-right: 10px;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .payment-message-error {
    float: right;
    text-align: right;
  }
}
