#updateaddress .content {
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);

  .control {
    float: none;
    margin: auto;
    padding: 5px 0;

    .ui-dropdown .ui-dropdown-item {
      text-transform: uppercase;
    }
  }
}

@media (max-width: 567px) {
  .updateaddress .content {
    .ui-paginator-prev, .ui-paginator-next {
      display: none;
    }
  }
}
