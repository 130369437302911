#commission .content {
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);

  .control {
    float: none;
    margin: auto;
    padding: 5px 20px;

    span {
      font-size: 16px;
    }
  }

  .ui-control {
    margin: 0;
    padding: 0;

    &.col-todate {
      padding-left: 10px;

      .ui-datepicker {
        right: 0;
      }
    }

    &.col-fromdate {
      padding-right: 10px;
    }
  }

  .ui-datepicker {
    min-width: 500px;
  }

  .ui-control-button {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    text-align: right;
  }

  .commission-title {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border: 1px solid #ccc;
    background-color: #f2f6f6;

    .commission-icon {
      color: gray;
      text-align: right;
      display: inline-block;
    }

    &.history {
      line-height: 50px;
      padding: 0 0 0 20px;
    }
  }

  .commission-table {
    overflow-x: auto;

    table, td, th {
      border: 1px solid black;
    }

    table {
      width: 100%;
      border: 1px solid #ccc;
      border-collapse: collapse;

      th {
        padding: 10px 5px;
        text-align: center;
        border: 1px solid #ccc;
      }

      td {
        padding: 10px 5px;
        border: 1px solid #ccc;

        &.td-center {
          text-align: center;
        }

        &.td-right {
          text-align: right;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #commission {
    .commission-table {
      border: 1px solid #ccc;
      border-bottom: none;
      border-top: none;
    }

    .ui-control {
      margin: 0;
      padding: 0;

      &.col-todate {
        margin-top: 10px;
        padding-left: 0 !important;
      }

      &.col-fromdate {
        padding-right: 0 !important;
      }
    }
  }
}
