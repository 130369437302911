@import 'variables';

.modal {
  .btn {
    min-width: 150px;
    color: $textColor;
    background-color: $buttonColor;

    &:hover {
      background-color: $buttonColor;
    }
  }

  .ui-button {
    color: $textColor;
    background-color: $buttonColor;

    &:hover {
      background-color: $buttonColor;
    }

    &.btn-cancel {
      &:hover {
        color: #fff;
        background-color: $buttonColor;
      }
    }
  }
}

.ticket {
  .ticket-header {
    .ticket-button {
      .ui-button {
        opacity: 1;
        background-color: $color;
      }
    }
  }
}

.ui-button {
  color: $textColor;
  background-color: $buttonColor;
}

#tracking {
  .driver-section-top {
    background-color: $color;
  }
}

#pickfloat {
  a {
    color: $color;
    cursor: pointer;

    &:hover {
      color: $buttonColor;
    }
  }

  .ui-button {
    color: $textColor;
    border-color: $buttonColor;
    background-color: $buttonColor;
  }

  .timeline-badge.active {
    background-color: $buttonColor !important;
  }
}

#pickcontact {
  a {
    color: $color;
    cursor: pointer;

    &:hover {
      color: $buttonColor;
    }
  }
}

.ui-dropdown {
  .ui-dropdown-item {
    border: none;

    &:hover,
    &.ui-state-highlight {
      border: none;
      color: #fff;
      background-color: $color;
    }
  }
}

.pagination {
  .page-item {
    .page-link {
      border: $color;
      color: $buttonColor;
      background-color: #fff;
    }

    &:hover,
    &.active {
      .page-link {
        color: $textColor;
        border: $buttonColor;
        background-color: $buttonColor;
      }
    }
  }
}

.page-account {
  margin: 10px 0;

  .container {
    .content {

      .section-left,
      .section-right {
        .menuheader {
          background-color: $buttonColor;
        }
      }

      .pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .dashboard-header-item {
        .buttons {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .ui-button {
            opacity: 1 !important;
            background-color: $buttonColor !important;

            &.white {
              background-color: #fff !important;

              &:hover {
                color: #fff !important;
                background-color: $buttonColor !important;
              }
            }
          }
        }
      }

      .dashboard-booking-item {
        .booking-navigate {
          background-color: $color;

          a {
            cursor: pointer;
            color: #fff !important;
          }

          button {
            opacity: 1;
            color: #fff;
            margin-left: 5px;
            background-color: $buttonColor !important;
          }
        }
      }

      .bookinghistory-dropdown {
        .ui-input {
          padding: 0;
        }
      }
    }
  }
}

#updateaddress {
  .ui-button {
    color: $textColor;
    border-color: $buttonColor;
    background-color: $buttonColor;
  }
}

#bookinghistory {
  .ui-button {
    color: $textColor;
    border-color: $buttonColor;
    background-color: $buttonColor;
  }
}

.ui-datepicker {

  .ui-header a:hover,
  .ui-header a.active,
  .ui-datepicker-next,
  .ui-datepicker-prev,
  .ui-time-content .ui-timepicker div a {
    opacity: 1;
    color: #fff;
    background-color: $color;

    &:hover {
      color: #fff;
      background-color: $buttonColor;
    }
  }

  .ui-datepicker-next,
  .ui-datepicker-prev {
    opacity: 1;
    color: #fff;
    background-color: $color;

    &:hover {
      color: #fff;
      background-color: $buttonColor;
    }
  }

  .ui-datepicker-calendar td a.ui-state-active {
    opacity: 1;
    color: #fff;
    border-color: $color !important;
    background-color: $color !important;
  }

  .ui-footer a {
    opacity: 1;
    color: #fff;
    background-color: $color;

    &:hover {
      color: #fff;
      background-color: $buttonColor;
    }

    .ui-footer a.btnCancel {
      background-color: #fff;
      border: 1px solid $color;

      &:hover {
        color: #fff;
        background-color: $buttonColor;
      }
    }
  }
}

.ui-nav-return {
  .nav.nav-pills {
    li {
      a {
        color: $buttonColor;

        &:hover {
          color: $textColor;
          background: $buttonColor;
        }
      }

      &.active {
        a {
          color: $textColor;
          background-color: $buttonColor;
        }
      }
    }
  }
}

#pickcard .nav {
  li {
    border-radius: 4px;
    color: $buttonColor;

    &:hover {
      color: $textColor;
      background: $buttonColor;

      a {
        color: inherit;
      }
    }

    &.active {
      color: $textColor;
      background-color: $buttonColor;
    }
  }
}

#pickfloat-bottom {
  background-color: #000;

  .item-float {
    .price {
      color: $textColor;
    }

    .promo-code {

      a,
      .glyphicon {
        color: $textColor;
      }
    }

    .price-return {
      color: $textColor;
    }

    .center-button {
      color: $textColor;
      background-color: $buttonColor;

      button {
        color: $textColor;
        background-color: transparent;
      }

      .time-expried {
        color: $textColor;
      }
    }

    .item-float-column {
      color: $textColor;

      a {
        color: $textColor;
      }
    }
  }
}

.ui-autocomplete-list {
  .ui-autocomplete-list-item {
    &:hover {
      background-color: $color;
    }
  }
}

.left_location_input .location_icon i {
  color: $buttonColor;
}

.f1-step.active .f1-step-icon {
  background: $color;
}

#pickup .ui-nav-return ul li.active {
  background-color: $color;
  border: 1px solid $color !important;
}