.ticket {
  .ticket-header {
    font-weight: bold;
    position: relative;

    .text {
      line-height: 22px;
      text-align: center;

      p {
        color: gray;
      }
    }

    .ticket-button {
      right: 0;
      top: -10px;
      text-align: right;
      position: absolute;

      button {
        color: #fff;
        opacity: 0.8;
        width: 110px;
        min-width: 0;
        outline: none;
        font-size: 14px;
        padding: 10px 0;
        font-weight: bold;
        text-transform: none;
        display: inline-block;
        border: 1px solid #fff;
        background-color: gray;

        img {
          margin-right: 10px;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .ticket-content {
    margin-top: 30px;
    border: 1px solid #ccc;

    .ticket-sub-header {
      padding: 5px 20px;
      text-transform: uppercase;
      background-color: #f2f6f9;

      .itemimage {
        width: 28px;
        margin-right: 10px;
        display: inline-block;

        img {
          width: 100%;
        }
      }
    }

    .ticket-item {
      padding: 10px 57px;

      fieldset {
        height: 140px;
        margin-bottom: 0;
        padding-bottom: 20px;
        border: 1px solid #ccc;

        legend {
          width: auto;
          font-size: 16px;
          margin-bottom: 12px;
          text-transform: uppercase;
        }

        &.fieldset-left {
          width: 48%;
          float: left;
        }

        &.fieldset-right {
          width: 48%;
          float: right;
        }
      }
    }

    .ticket-price {
      text-align: right;
      margin-bottom: 10px;

      button {
        color: #fff;
        border: none;
        cursor: default;
        padding: 15px 20px;
        text-transform: uppercase;
        background-color: #eb273d;

        span {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .ticket .ticket-header {
    padding-top: 45px;
  }

  .ticket-item {
    &.ticket-item-fieldset {
      padding: 10px 15px !important;
    }

    &.ticket-price {
      text-align: center !important;
      padding: 10px 15px !important;
    }

    fieldset {
      &.fieldset-left {
        margin: 0;
        width: 100% !important;
        margin-bottom: 10px !important;
      }

      &.fieldset-right {
        margin: 0;
        width: 100% !important;
      }
    }
  }
}
