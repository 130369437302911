.ui-autocomplete {
    width: 100% !important;
}

.ui-autocomplete .ui-autocomplete-panel {
    min-width: 100%;
    overflow-x: hidden;
    width: 100% !important;
    font-size: 16px;
    border-radius: 0 !important;
    box-shadow: none !important;
    min-width: calc(100% - 17px);
}

.ui-autocomplete .ui-autocomplete-dropdown {
    height: 43px !important;
    width: 50px !important;
    top: 1px;
}

.ui-autocomplete .ui-autocomplete-input {
    padding-left: 50px;
    padding-right: 100px;
}

.ui-autocomplete-list {
    z-index: 1;
    overflow-y: auto;
    background-color: #fff;
}

.ui-autocomplete-list .ui-autocomplete-list-item {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
    background-color: #fff;
}

.ui-autocomplete-list .ui-autocomplete-list-item .ui-icon-template {
    width: 24px;
    height: auto;
    margin-right: 10px;
}

.ui-autocomplete-list .ui-autocomplete-list-item div,
.ui-autocomplete-list .ui-autocomplete-list-item span {
    margin: 0 !important;
    border: none !important;
}

.ui-autocomplete-list .ui-autocomplete-list-item .item-icon {
    float: left;
}

.ui-autocomplete-list .ui-autocomplete-list-item .item-icon img {
    width: 30px;
    height: 30px;
}

.ui-autocomplete-list .ui-autocomplete-list-item .item-text {
    float: left;
    font-size: 1em;
    padding-top: 5px;
    padding-left: 10px;
    white-space: normal;
    width: calc(100% - 40px);
}

.ui-autocomplete-list .ui-autocomplete-list-item:hover {
    background-color: #186ba0;
}

.ui-autocomplete-list .ui-autocomplete-list-item:hover div,
.ui-autocomplete-list .ui-autocomplete-list-item:hover span {
    color: #fff;
    border: none !important;
    background-color: transparent !important;
}

/* Input */
/* Button */
/* Modal */
/*Responsive Variables*/
.pac-container .pac-item {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
}

.pac-container .pac-item .pac-icon-marker {
    margin-top: 12px;
    margin-left: 10px;
    margin-right: 15px;
}

.pac-container .pac-item:hover {
    color: #fff;
    background-color: #0f76bb;
}

.pac-container .pac-item:hover .pac-item-query {
    color: #fff;
}

.pac-container .pac-item:hover .pac-matched {
    color: #fff;
    opacity: 0.8;
}

.ui-helper-hidden-accessible {
    display: none !important;
}