* {
  margin: 0;
  padding: 0;
}
select {
  appearance: none;
}
a[rel="noopener"] {
  display: none !important;
}
html.noscroll {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
}
.desktop {
  display: block;
}
.mobile {
  display: none;
}
.center {
  display: block !important;
  margin-left: auto !important;
  text-align: center !important;
  margin-right: auto !important;
}
.hint-invalid span {
  color: red;
  display: block;
  font-size: 16px;
  padding-top: 5px;
  background-color: transparent;
}
.paddingleft {
  padding-left: 10px;
}
.paddingright {
  padding-right: 10px;
}
@media (max-width: 767px) {
  .paddingleft {
    padding-left: 0;
    padding-top: 10px;
  }
  .paddingright {
    padding-right: 0;
  }
}
