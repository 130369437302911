#updateprofile {
  .section-title {
    color: #3699dd;
    margin-bottom: 80px;

    p {
      color: #666666;
      font-size: 16px;
      padding-top: 20px;
      font-weight: normal;
      text-transform: none;
    }
  }

  .content {
    padding: 30px;
    color: #808080;
    margin-top: 30px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);

    div {
      margin: 0;
      padding: 0;
    }

    .control {
      float: none;
      margin: auto;
      padding: 5px 0;

      .selected-flag {
        .iti-flag {
          margin-left: 10px;
        }

        .selected-dial-code {
          padding-left: 34px;
        }
      }

      span {
        font-size: 16px;

        &.ui-chkbox-icon {
          font-size: 14px;
        }
      }
    }
  }
}
