.link-more-options {
    display: flex;
    font-size: 16px;
    margin-top: 10px;
    user-select: none;
    position: relative;
    justify-content: space-between;

    span.link {
        cursor: pointer;
        color: #3699DD;
        text-align: right;
    }
    span.text {
        color: #211f1f;
        text-align: left;
        transition: all 0.5s;
        &.deactive {
            opacity: 0;
        }
        .red {
            color: red;
        }
    }
}

.add-options-vehicle {
    height: 0;
    overflow: hidden;
    margin: 0 !important;
    transition: all 0.5s;

    &.active {
        opacity: 1;
        height: auto;
        overflow: visible;
        pointer-events: all;
        margin-top: 35px !important;
    }
}

.add-options-content {
    height: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    pointer-events: none;
    transition: all 0.5s;
    justify-content: space-between;

    &.active {
        opacity: 1;
        height: auto;
        overflow: visible;
        pointer-events: all;
    }

    .item {
        width: 49%;
        overflow: visible;
        position: relative;

        #pickup-passenger {
            .icon-last {
                pointer-events: none;
            }
        }

        #pickup-luggage {
            .icon-last {
                pointer-events: none;
            }
        }

        .popupOptions {
            top: 100%;
            opacity: 0;
            z-index: -1;
            padding: 20px;
            margin-top: 20px;
            position: absolute;
            width: fit-content;
            pointer-events: none;
            transition: all 0.5s;
            background-color: #fff;
            box-shadow: 0 0 5px #ccc;

            &.active {
                opacity: 1;
                z-index: 12;
                margin-top: 0;
                pointer-events: all;
            }

            &.top50 {
                top: 50px;
            }

            .title {
                display: flex;
                font-size: 16px;
                margin-bottom: 10px;
                justify-content: space-between;

                &.title-center {
                    margin-top: 15px;
                }

                span {
                    display: block;
                    white-space: nowrap;

                    &.cancel {
                        color: red;
                        cursor: pointer;
                    }
                }
            }

            .popupItem {
                display: flex;
                margin-top: 5px;
                font-size: 16px;
                min-width: 320px;
                align-items: center;

                .subtitle {
                    color: #211f1f;
                    overflow: hidden;
                    margin-left: 10px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .dropdownWapper {
                    width: 150px;
                    min-width: 150px;
                    position: relative;

                    .dropdown {
                        margin-bottom: 0;

                        dt a {
                            width: unset;
                            margin-left: 50px;
                        }

                        dd ul {
                            z-index: 2;

                            a {
                                line-height: 30px;

                                label {
                                    margin: 0 !important;
                                }
                            }
                        }
                    }

                    .ui-dropdown-panel {
                        max-height: 220px;
                        min-height: 145px;
                    }

                    .ui-dropdown-label {
                        padding-left: 15px !important;
                    }
                }
            }

            .button {
                display: none;
                margin-top: 10px;
                text-align: center;

                button {
                    width: 100px;
                }
            }

            .description {
                display: none;
                margin-top: 10px;
                text-align: center;
            }
        }
    }

    .item-vehicle {
        margin-top: 20px;
    }

    .ui-dropdown-label,
    .dropdown-text {
        height: 47px;
        display: flex;
        padding-right: 50px;
        padding-left: 0 !important;
        overflow: visible !important;
        justify-content: space-between;

        .labelitem {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 48px;
            display: inline-flex;

            .label-icon {
                padding: 0;
                width: 50%;
                margin: 0px;
                height: 100%;
                display: flex;
                min-width: 28px;
                max-width: 40px;
                align-items: center;
                background-color: #eee;
                justify-content: space-between;

                img {
                    width: 22px;
                    height: unset;
                    margin: 0 auto;
                    vertical-align: unset;
                }
            }

            label {
                margin: 0;
                flex-grow: 1;
                min-width: 30px;
                line-height: 50px;
                text-align: center;
                font-weight: normal;
            }
        }
    }

    .tooltipicon {
        position: relative;

        .tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: #eee;
            color: #211f1f;
            text-align: center;
            border-radius: 6px;
            position: absolute;
            z-index: 1;
            bottom: calc(100% + 8px);
            left: 50%;
            margin-left: -60px;
            font-weight: normal;
            font-size: 14px;
            line-height: 32px;
            font-weight: normal !important;
            text-transform: none !important;

            &:after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #eee transparent transparent transparent;
            }
        }

        &:hover {
            .tooltiptext {
                visibility: visible;
            }
        }
    }
}

@media (max-width: 567px) {
    .add-options-content {
        flex-direction: column;

        .item {
            &:first-child {
                margin-bottom: 10px;
            }

            width: 100%;
            .popupOptions {
                width: 100%;
            }
        }
    }
}