﻿.card-type-icon {
    top: 14px;
    opacity: 1;
    z-index: 1;
    right: 10px;
    width: 32px;
    height: 23px;
    display: block;
    position: absolute;
    pointer-events: none;
    transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    -ms-transition: opacity .15s linear;
    -moz-transition: opacity .15s linear;
    -webkit-transition: opacity .15s linear;
    background: url(../images/icons/cards.png) 0 23px no-repeat;
}

    .card-type-icon.show {
        opacity: 1;
    }

    .card-type-icon.visa {
        background-position: 0 0;
    }

    .card-type-icon.mastercard {
        background-position: -32px 0;
    }

    .card-type-icon.amex {
        background-position: -64px 0;
    }

    .card-type-icon.discover {
        background-position: -96px 0;
    }

    .card-type-icon.dinersclub {
        background-position: -128px 0;
    }

    .card-type-icon.jcb {
        background-position: -160px 0;
    }